import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import lanyard_material_options from './optiondata/LanyardMaterialOptions'
import lanyard_strap_size_options from './optiondata/LanyardStrapSizes'
import lanyard_accessories from './optiondata/LanyardAccessories'
import lanyard_color_options from './optiondata/LanyardColorOptions'
import lanyard_joining_options from './optiondata/LanyardJoiningOptions'
import lanyard_printing_method_options from './optiondata/LanyardPrintingMethodOptions'
import lanyard_pvc_card_options from './optiondata/LanyardPvcCardOptions'
import lanyard_pvc_cardholder_options from './optiondata/LanyardPvcCardholderOptions'
import lanyard_safety_buckle_options from './optiondata/LanyardSafetyBuckleOptions'
import { Alerts, GetLanyardPrintingEnquiry } from '../../../helper';
import {LanyardEnquiryResponce} from './LanyardEnquiryResponce'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Lanyard Printing Enquiry",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]


export default class LanyardPrintingEnquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidediv1: true,
            hidediv2: true,
            selectedLanyardMaterial: '',
            selectedLanyardStrapSize: '',
            selectedPrintingMethod: '',
            selectedLanyardJoining: '',
            selectedLanyardSafetyBuckle: '',
            selectedPvcCardHolder: '',
            selectedPvcCard: '',
            selectedColors: [],
            selectedAccessories: [],
            name: '',
            email: '',
            phone: '',
            quantity: '',
            message: '',
            attachment: null,
            isValidName: false,
            isValidEmail: false,
            isValidPhone: false,
            isValidQuantity: false,
            isValidMessage: false,
            messageError: '',
            quantityError: '',
            phoneError: '',
            emailError: '',
            nameError: '',
            isValidMaterial: false,
            isValidStrapSize: false,
            isValidPrintingMethod: false,
            isValidJoining: false,
            lanyardMaterialError: '',
            lanyardStrapSizeError: '',
            lanyardPrintingMethodError: '',
            lanyardJoiningError: '',
            isLoading:false
          

        }
    }
    showDiv1 = (e) => {
        e.preventDefault();
        this.setState({
            hidediv1: !this.state.hidediv1
        })
    }
    showDiv2 = (e) => {
        e.preventDefault();
        this.setState({
            hidediv2: !this.state.hidediv2
        })
    }
    handleOnChangeMeterial = (e) => {
        this.setState({
            selectedLanyardMaterial: e.target.value
        }, () => {
            this.validationMeterialOption(this.state.selectedLanyardMaterial);
        });
    }

    /********Validation *********/
    validationMeterialOption = (data) => {
        if (data === '') {
            this.setState({
                lanyardMaterialError: 'Lanyard meterial option is required',
                isValidMaterial: false,
            })
        } else {
            this.setState({
                lanyardMaterialError: '',
                isValidMaterial: true,
            })
        }
    }

    handleOnChangeStrapSize = (e) => {
        this.setState({
            selectedLanyardStrapSize: e.target.value
        }, () => {
            this.validationStrapSize(this.state.selectedLanyardStrapSize);
        });
    }

    /********Validation *********/
    validationStrapSize = (data) => {
        if (data === '') {
            this.setState({
                lanyardStrapSizeError: 'Lanyard strap size option is required',
                isValidStrapSize: false,
            })
        } else {
            this.setState({
                lanyardStrapSizeError: '',
                isValidStrapSize: true,
            })
        }
    }



    handleOnChangePrintingMethod = (e) => {
        this.setState({
            selectedPrintingMethod: e.target.value
        }, () => {
            this.validationPrintingMethodOption(this.state.selectedPrintingMethod);
        });
    }

    /********Validation *********/
    validationPrintingMethodOption = (data) => {
        if (data === '') {
            this.setState({
                lanyardPrintingMethodError: 'Lanyard printing method option is required',
                isValidPrintingMethod:false
            })
        } else {
            this.setState({
                lanyardPrintingMethodError: '',
                isValidPrintingMethod:true
            })
        }
    }


    handleOnChangeSafetyBuckle = (e) => {
        this.setState({ selectedLanyardSafetyBuckle: e.target.value });
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        }, () => {
            this.validationName(this.state.name);
        });
    }

    /********Validation *********/
    validationName = (data) => {
        if (data === '') {
            this.setState({
                nameError: 'Name is required',
                isValidName: false
               
            })
        } else {
            this.setState({
                nameError: '',
                isValidName: true
            })
        }
    }


    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        }, () => {
            this.validationEmail(this.state.email);
        });
    }

    /********Validation *********/
    validationEmail = (data) => {
        if (data === '') {
            this.setState({
                emailError: 'Email is required',
                isValidEmail: false
            })
        } else {
            this.setState({
                emailError: '',
                isValidEmail: true
            })
        }
    }


    handlePhoneChange = (e) => {
        this.setState({
            phone: e.target.value
        }, () => {
            this.validationPhone(this.state.phone);
        });
    }
    /********Validation *********/
    validationPhone = (data) => {
        if (data === '') {
            this.setState({
                phoneError: 'Phone is required',
                isValidPhone: false
            })
        } else {
            this.setState({
                phoneError: '',
                isValidPhone: true,
                
            })
        }
    }

    handleQuantityChange = (e) => {
        this.setState({
            quantity: e.target.value
        }, () => {
            this.validationQuantity(this.state.quantity);
        });
    }

    /********Validation *********/
    validationQuantity = (data) => {
        if (data === '') {
            this.setState({
                quantityError: 'Quantity is required',
                isValidQuantity: false
            })
        } else {
            this.setState({
                quantityError: '',
                isValidQuantity: true
            })
        }
    }

    handleMessageChange = (e) => {
        this.setState({
            message: e.target.value
        }, () => {
            this.validationMessage(this.state.message);
        });
    }
    /********Validation *********/
    validationMessage = (data) => {
        if (data === '') {
            this.setState({
                messageError: 'Message is required',
                isValidMessage: false
            })
        } else {
            this.setState({
                messageError: '',
                isValidMessage: true
            })
        }
    }

    handleOnChangePvcCardHolder = (e) => {
        this.setState({ selectedPvcCardHolder: e.target.value });
    }

    handleOnChangePvcCard = (e) => {
        this.setState({ selectedPvcCard: e.target.value });
    }

    handleOnChangeJoining = (e) => {
        this.setState({
            selectedLanyardJoining: e.target.value
        }, () => {
            this.validationLanyardJoiningOption(this.state.selectedLanyardJoining);
        });
    }
    /********Validation *********/
    validationLanyardJoiningOption = (data) => {
        if (data === '') {
            this.setState({
                lanyardJoiningError: 'Lanyard joining option is required',
                isValidJoining:false
            })
        } else {
            this.setState({
                lanyardJoiningError: '',
                isValidJoining:true
            })
        }
    }

    handleInputChange(e) {
        const options = this.state.selectedColors
        let index

        // check if the check box is checked or unchecked
        if (e.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(e.target.value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(e.target.value)
            options.splice(index, 1)
        }

        // update the state with the new array of options
        this.setState({ selectedColors: options })

    }
    handleInputChangeAccessories(e) {
        const options = this.state.selectedAccessories
        let index

        // check if the check box is checked or unchecked
        if (e.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(e.target.value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(e.target.value)
            options.splice(index, 1)
        }

        // update the state with the new array of options
        this.setState({ selectedAccessories: options })
    }

 

      handleAttachmentChange = (e) => {
        this.setState({
            attachment:e.target.files[0],
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading:true
        })
        const { selectedLanyardMaterial, selectedLanyardStrapSize, selectedPrintingMethod, selectedLanyardJoining, selectedLanyardSafetyBuckle, selectedPvcCardHolder, selectedPvcCard, selectedColors, selectedAccessories, name, email, phone, quantity, message, attachment} = this.state;

        this.validationMeterialOption(selectedLanyardMaterial);
        this.validationStrapSize(selectedLanyardStrapSize);
        this.validationPrintingMethodOption(selectedPrintingMethod);
        this.validationLanyardJoiningOption(selectedLanyardJoining);
        this.validationName(name);
        this.validationEmail(email);
        this.validationPhone(phone);
        this.validationQuantity(quantity);
        this.validationMessage(message);
  
       
        if(this.state.isValidName && this.state.isValidEmail && this.state.isValidPhone &&  this.state.isValidQuantity &&  this.state.isValidMessage &&  this.state.isValidMaterial &&  this.state.isValidStrapSize &&  this.state.isValidPrintingMethod &&  this.state.isValidJoining){
            const formData = new FormData();
            formData.append('name',name);
            formData.append('email',email);
            formData.append('phone',phone);
            formData.append('quantity',quantity);
            formData.append('message',message);
            formData.append('selectedLanyardMaterial',selectedLanyardMaterial);
            formData.append('selectedLanyardStrapSize',selectedLanyardStrapSize);
            formData.append('selectedPrintingMethod',selectedPrintingMethod);
            formData.append('selectedLanyardJoining',selectedLanyardJoining);
            formData.append('selectedLanyardSafetyBuckle',selectedLanyardSafetyBuckle);
            formData.append('selectedPvcCardHolder',selectedPvcCardHolder);
            formData.append('selectedPvcCard',selectedPvcCard);
            formData.append('selectedColors',selectedColors);
            formData.append('selectedAccessories',selectedAccessories);
            formData.append('attachment',attachment);

            let resp = await GetLanyardPrintingEnquiry.getLanyardPrintingEquiry(formData);
            if(resp){
               if(resp.status === true){
                this.setState({
                    isLoading:false
                });
              
                window.location.reload(this.props.history.push({
                    pathname:"/lanyard-enquiry-responce",
                    state:{
                        enquiryDetails:resp.enquiryDetails.id
                     }
                   }))
               }

               if(resp.status === false){
                this.setState({
                    isLoading:false
                });
                Alerts.alertMessage(resp.message, "Input Error", "danger");
               }
               
            }
        }else{
            this.setState({
                isLoading:false
            });
            Alerts.alertMessage("Please fill all required fields.", "Input Error", "danger");
        }

       


    }
    render() {
        const { hidediv1, hidediv2, selectedLanyardMaterial, selectedLanyardStrapSize, selectedPrintingMethod, selectedLanyardJoining, selectedLanyardSafetyBuckle, selectedPvcCardHolder, selectedPvcCard, lanyardMaterialError, lanyardStrapSizeError, lanyardPrintingMethodError, lanyardJoiningError, name, email, phone, quantity, message, messageError, quantityError, phoneError,emailError,nameError, isLoading } = this.state;
 
        return (
            <div className="content-wrapper">
                <Breadcrumb BreadcrumbData={BreadcrumbData} />
                <div className="container">
                    <div className="page-desc">
                        <div className="section-title text-center">
                            <h2>Lanyard Printing Enquiry</h2>
                            <div className="border-bottom-line">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="lanyard-form-wrapper">
                            <form onSubmit={this.handleSubmit}>
                                <ul class="step-ul">
                                    <li><h5 class="stitle"><span>1</span>CHOOSE LANYARD MATERIAL</h5>
                                        <div className="form-content-inner">
                                            <span className="lanyardErrorMessage">{lanyardMaterialError}</span>
                                            <p>Different materials of Lanyard will restrict your printing methods. Once you have decided which material to use, you will go following steps to check on the available printing method and lanyard width options. </p>
                                            <div className={hidediv1 ? "hide-div" : ""}>
                                                <p>1. Full-Colour Printed Lanyard</p>
                                                <p>The only one material Smooth Nylon that can be used to print full-colour heat transfer as in the surface is smooth and shiny.</p>

                                                <p>2. Smooth Nylon Lanyard</p>
                                                <p>Smooth Nylon very durable and comfortable just because the fabric is thin and light. The smooth surface would create a much better printing result compare to a rougher surface.</p>

                                                <p>3. Polyester Lanyard</p>
                                                <p>Rough Surface made with 100% polyester or Nylon. If your logo only consists one colour, this material probably will be your option.</p>

                                                <p>4.Woven Lanyard</p>
                                                <p>The only lanyard allow you to do embroidery with a very rough and strong polyester material. It comes with a stitch textured feeling.</p>

                                                <p>5. Tubular Lanyard</p>
                                                <p>Tubular is similar to the shoelaces. It is made of the tube-stitched polyester material .You can feel that this material thick and comfortable. The sizes & colours of lanyard are limited.</p>

                                                <p>6. Dual Hooks Smooth Nylon Lanyard</p>
                                                <p>Lanyard with two hooks accessories that allow user to have stronger holding power to hold ID card.</p>
                                            </div>
                                            <button onClick={this.showDiv1} style={{ marginBottom: '15px' }}>{hidediv1 ? "Read More" : "Read Less"}</button>

                                        </div>

                                        <div className="box-main">
                                            <div className="row">

                                                {lanyard_material_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="radio" name="lanyard_material" value={choice.value} checked={selectedLanyardMaterial === choice.value} onChange={(e) => this.handleOnChangeMeterial(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/material/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>

                                    </li>
                                    <li><h5 class="stitle"><span>2</span>CHOOSE LANYARD STRAP SIZE (MM) </h5>
                                        <div className="form-content-inner">
                                            <span className="lanyardErrorMessage">{lanyardStrapSizeError}</span>
                                            <p>Choose lanyard straps of differing widths for your customized lanyard. Different material selection have different sizes availability.</p>
                                        </div>
                                        <div className="box-main">
                                            <div className="row">

                                                {lanyard_strap_size_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="radio" name="lanyard_strap_size" value={choice.value} checked={selectedLanyardStrapSize === choice.value} onChange={(e) => this.handleOnChangeStrapSize(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/strap-size/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </li>
                                    <li><h5 class="stitle"><span>3</span>CHOOSE LANYARD PRINTING METHOD (IMPORTANT) </h5>

                                        <div className="form-content-inner">
                                            <span className="lanyardErrorMessage">{lanyardPrintingMethodError}</span>
                                            <p> Do ask yourself, what printing method do you want your design to be? As different printing method will allow you which material and colour to choose from.</p>
                                            <div className={hidediv2 ? "hide-div" : ""}>
                                                <p>A-Lanyard Heat Transfer Printing / Dye-sublimation Lanyard Printing (MOST POPULAR IN MARKET) is using CMYK printing colour method and is known for its multi-colour complexity prints which allow for gradients and complex design to be printed. This is the only method which could fit for any designs, colours, gradient colour and etc. Even though the printing result of the heat transfer print is not that vibrant as clear as silkscreen printing, it is the only method that can print whatever which the silkscreen printing cannot be done. The result of the printing will amaze you for sure.</p>

                                                <p>B-Lanyard Silkscreen Printing is one of the most common lanyard printing methods for its durability and vibrancy of the printed colours. This printing method is the same as the T-shirt or corporate gift silkscreen printing, it is charged based on the number of colours. Thus you have to go with this method when your artwork is in only single color. Every Material can be use with this method.</p>

                                                <p>C- Embroidery<br /> This method only allow you to do on woven material, your logo would be direct embroider onto the lanyard.</p>
                                            </div>
                                            <button onClick={this.showDiv2} style={{ marginBottom: '15px' }}>{hidediv2 ? "Read More" : "Read Less"}</button>
                                        </div>
                                        <div className="box-main">
                                            <div className="row">
                                                {lanyard_printing_method_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="radio" name="lanyard_printing_method" value={choice.value} checked={selectedPrintingMethod === choice.value} onChange={(e) => this.handleOnChangePrintingMethod(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/printing-method/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </li>
                                    <li><h5 class="stitle"><span>4</span>CHOOSE COLOR OF LANYARD </h5>
                                        <div className="box-main">
                                            <div className="row">
                                                {lanyard_color_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="checkbox" name="lanyard_color[]" value={choice.value} onChange={(e) => this.handleInputChange(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/lanyard-color/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </li>
                                    <li><h5 class="stitle"><span>5</span>CHOOSE LANYARD ACCESSORIES / HOOKS </h5>
                                        <div className="form-content-inner">
                                            <p> Choose from the various type of lanyard hooks to complete the set of your lanyard. Different type of hooks carrying different usage for holding your belonging. So free to you to mix and match the accessories.</p>

                                            <p>You can select more than 1 types.<br />
                                                If you cant find the accessories below, kindly do attach the design below or send the enquiry to our email address sales.luminousprinting@gmail.com</p>
                                        </div>

                                        <div className="box-main">
                                            <div className="row">

                                                {lanyard_accessories.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="checkbox" name="lanyard_accessories[]" value={choice.value} onChange={(e) => this.handleInputChangeAccessories(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/lanyard-accessories/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>

                                        </div>
                                    </li>
                                    <li><h5 class="stitle"><span>6</span>CHOOSE LANYARD JOINING </h5>
                                        <div className="form-content-inner">

                                            <span className="lanyardErrorMessage">{lanyardJoiningError}</span>
                                            <p> Choose to have your lanyard stitched, riveted or crimped to suit your customized lanyard look and feel. Each of these lanyard joining methods will also have their pros and cons.</p>
                                        </div>
                                        <div className="box-main">
                                            <div className="row">
                                                {lanyard_joining_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="radio" name="lanyard_joining" value={choice.value} checked={selectedLanyardJoining === choice.value} onChange={(e) => this.handleOnChangeJoining(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/lanyard-joining/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>

                                    </li>
                                    <li><h5 class="stitle"><span>7</span>REQUIRE A SAFETY BREAKAWAY OR SAFETYBUCKLE? (OPTIONAL) </h5>
                                        <div className="form-content-inner">

                                            <p> Does your customized lanyard need this breakaway buckle? Yes, it is for safety</p>

                                            <p>The Lanyard Safety Breakaway prevents the wearer from being choked or strangled by the lanyard. It is generally stitched in the back of the lanyard and is designed to come loose when a lanyard is snagged or pulled on with force, thus this is a highly recommended add-on.</p>

                                            <p>Buckle is the most recommended as in you can removing your belonging by release the buckle without takeout from your neck.</p>
                                        </div>

                                        <div className="box-main">
                                            <div className="row">
                                                {lanyard_safety_buckle_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="radio" name="lanyard_safety_buckle" value={choice.value} checked={selectedLanyardSafetyBuckle === choice.value} onChange={(e) => this.handleOnChangeSafetyBuckle(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/safety-buckle/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </li>
                                    <li><h5 class="stitle"><span>8</span>CHOOSE LANYARD PLASTIC PVC CARD HOLDER (OPTIONAL) </h5>
                                        <div className="form-content-inner">
                                            <p> No worry if you need a card holder from Luminous. We offer a different range of cardholders for your selection such as PVC, PU leather, hard case and etc..</p>
                                        </div>
                                        <div className="box-main">
                                            <div className="row">
                                                {lanyard_pvc_cardholder_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="radio" name="lanyard_pvc_cardholder" value={choice.value} checked={selectedPvcCardHolder === choice.value} onChange={(e) => this.handleOnChangePvcCardHolder(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/pcv-holder/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </li>
                                    <li><h5 class="stitle"><span>9</span>DO YOU REQUIRE A LANYARD PVC CARD ID/STAFF ID? (OPTIONAL) </h5>
                                        <div className="form-content-inner">
                                            <p> Furthermore, we also offer a landscape or portrait design of ID card. Most of the card either come with a punch hole for holding with the lanyard.</p>
                                        </div>
                                        <div className="box-main">
                                            <div className="row">
                                                {lanyard_pvc_card_options.map((choice, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="box">
                                                            <input type="radio" name="lanyard_pvc_card" value={choice.value} checked={selectedPvcCard === choice.value} onChange={(e) => this.handleOnChangePvcCard(e)} />
                                                            <article>
                                                                <div className="form-img-box">
                                                                    <img src={process.env.PUBLIC_URL + "../assets/img/lanyard-img/pcv-card/" + choice.image} alt="" />
                                                                </div>
                                                                <p>{choice.text}</p>
                                                            </article>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </li>
                                    <li><h5 class="stitle"><span>10</span>Form </h5>
                                        <div className="form-content-inner">
                                            <p>If you have any other specific requirement, you may directly send to our email sales.luminousprinting@gmail.com or Whatsapp +6591195104 for further assistance.</p>

                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label>Name</label>
                                                    <input type="text" className="form-control" name="name" value={name} onChange={this.handleNameChange} />
                                                    <span className="lanyardErrorMessage">{nameError}</span>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" name="email" value={email} onChange={this.handleEmailChange} />
                                                    <span className="lanyardErrorMessage">{emailError}</span>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Phone</label>
                                                    <input type="number" className="form-control" name="phone" value={phone} onChange={this.handlePhoneChange} />
                                                    <span className="lanyardErrorMessage">{phoneError}</span>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Quantity</label>
                                                    <input type="number" className="form-control" name="quantity" value={quantity} onChange={this.handleQuantityChange} />
                                                    <span className="lanyardErrorMessage">{quantityError}</span>
                                                </div>
                                                <div className="form-group col-12">
                                                    <label>Message</label>
                                                    <textarea className="form-control" name="message" onChange={this.handleMessageChange}>{message}</textarea>
                                                    <span className="lanyardErrorMessage">{messageError}</span>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Attachment</label>
                                                    <input type="file" className="form-control" name="attachment" onChange={ this.handleAttachmentChange }/>
                                                  <small>Please upload only zip, ai, pdf, png, jpg  files.</small>
                                                </div>
                                                <div className="form-group">
                                                    {/* <input type="submit" className="primary-btn color-white" value="Submit" /> */}
                                                    <button type="" className="primary-btn color-white">{ isLoading ? 'Sending ...':'Submit' }</button>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
