import api from '../../ApiConfig';
import { Apis } from '../../config';

const getLanyardPrintingEquiry = async (data) => {
    try {
        let result = await api.post(Apis.LanyardPrintingEnquiry, data);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



const exportedObject = {
    getLanyardPrintingEquiry,
};
export default exportedObject;