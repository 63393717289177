import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { GetPages } from '../../../helper'
import parse from 'html-react-parser'
export default class AboutSection extends Component {
    constructor(){
        super();
        this.state={
          isLoading:true,
          pageDetails:null
        }
      }
      async componentDidMount(){
      
        let resp = await GetPages.getAboutDetails();
        if(resp){
            this.setState({
                pageDetails: resp.aboutDetails,
                isLoading:false
            })
        }else{
            this.setState({
             isLoading:false
         }) 
      }
    }
    render() {
        const {pageDetails, isLoading} = this.state; 
        return (
            <>
            {
                !isLoading ?
           
            <div className="service-bottom section-padding">
            <div className="container">
                <div className="section-title text-center">
                    <h2>{pageDetails.title}</h2>
                    <div className="border-bottom-line">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="service-bottom-content text-center">
                    <p><strong> {pageDetails.subtitle}</strong></p>
                    <p className="mb-30">{parse(pageDetails.excerpt)}</p>
                    <Link to="/about" className="primary-btn color-white ">Read More</Link>
                </div>

             
            </div>
        </div>:null
    }
        </>
        )
    }
}
