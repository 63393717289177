import React from 'react'
import {Link} from "react-router-dom";
const HomeBanner = () => {
    return (
        <div className="banner">
        <div className="container postistion-rl">
        <div className="google-ratting">
          <img src={process.env.PUBLIC_URL + "./assets/img/g-icon.png"} alt=""/>
        </div>
        
           <div className="row">
               <div className="col-6 col-sm-12">
               <div className="banner-content">
               <div className="banner-top-text">
                <h2><span>Printing Singapore: </span> <br/> Cheap, Fast, & Good Quality</h2>
                </div>
                <div className="banner-bottom-text">
                    <h4>⚡Get Prices Instantly!</h4>
                    <p>Browse over 200+ products with Online Instant Price Quotes today!</p>
                </div>
                <div className="banner-bottom-btn">
                  <Link to="#">Get quote for t shirt printing</Link>
                  <Link to="#">Get quote for Corporate gifts</Link>
                </div>
                </div>
               </div>
               <div className="col-6 col-sm-12 text-center banner-img">
               <img src={process.env.PUBLIC_URL + "./assets/img/main-banner.svg"} alt="Luminous Printing"/>
               
            </div>
           </div>
           </div>
        </div>
    )
}

export default HomeBanner
