import React, { Component } from 'react'
import PrintingMethodsItems from './printing-methods/PrintingMethodsItems'
import {GetPages} from '../../../helper'
export default class PrintingServices extends Component {
    constructor(){
        super();
        this.state={
            printing_methods:null,
            isLoading:true
        }
    }
    async componentDidMount() {
        let resp = await GetPages.getPrintingMethods();
        if(resp){
            this.setState({
                printing_methods: resp.printing_method,
                isLoading:false
            })
        }else{
            this.setState({
             isLoading:false
         }) 
        }
      }
    render() {
        const {printing_methods, isLoading} = this.state;
      
        return (
            <>
    <div className="printing-service-section section-padding">
      <div className="container">
          <div className="section-title text-center">
            <h2>T-shirt Printing Methods</h2>
            <div className="border-bottom-line">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

 

           {
                        isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>

                        :<><div className="row">
                            {
                                printing_methods.map((item, index) => {
                                    return <PrintingMethodsItems key={index} item={item} />
                                }) 
                            }


                        </div>
                        </>
                    }
           
          
                

                
     
          </div>
      </div>
        </>
        )
    }
}
