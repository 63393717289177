import { store } from 'react-notifications-component';

const alertMessage = async (message, title, type) => {
    try {
        store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
    } catch (error) {
        console.log(error);
        return null;
    }
};
const exportedObject = {
    alertMessage
};
export default exportedObject;
