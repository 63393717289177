import React from 'react'


const UserEnquiriesItems = ({item, onViewOrderEnquiry}) => {
    return (
        <div className="col-4">
         <div className="card">
             <div className="card-header order-header">
                 <h3>Order ID : { item.id }</h3>
             </div>
             <div className="card-body order-details">
                 <p><strong>Billing Name</strong> : { item.billing_name } </p>
                 <p><strong>Billing email</strong> : { item.billing_email } </p>
                 <p><strong>Billing Phone</strong> : { item.billing_phone } </p>
                 <p><strong>Billing Organization</strong> : { item.billing_organization } </p>
                 <p><strong>Created_at</strong> : { item.created_at } </p>
             </div>
             <div className="card-footer order-footer">
               <div className="order_status">
               <span>{ item.status }</span> 
               </div>
               <div className="order_view">
                   <button onClick={()=>onViewOrderEnquiry(item.id)}>View</button>
               </div>
             </div>
         </div>
        </div>
    )
}

export default UserEnquiriesItems
