import React, { Component } from 'react'
import {NavLink} from "react-router-dom";
import Logout from './Logout';
export default class Sidebar extends Component {
    render() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        return (
            <div className="menu-listing-box">
                <div class="menu-listing-header"><h3>User Dashbord</h3></div>
                <div className="menu-list account-menu-list">
                    <ul>
                        <li><NavLink to="/user/dashboard" activeClassName="active">User Information</NavLink></li>
                        {
                           userData && userData.provider !== null ? null : <li><NavLink to="/user/change-password" activeClassName="active">Change Password</NavLink></li>}
                        <li><NavLink to="/user/enquiries" activeClassName="active">User Enquiries</NavLink></li>
                        <li><Logout/></li>
                    </ul>
                </div>
            </div>
        )
    }
}
