import React from 'react'
import {Link} from "react-router-dom";
function Breadcrumb(props) {
    return (
        <div className="breadcrumb">
         <div className="container">
         <ul>
           {props.BreadcrumbData.map((item, index) => {
                return <li key={index}><Link to={item.page_path}>{item.page_title}</Link></li>
            })}
            </ul>
         </div>  
        </div>
    )
}

export default Breadcrumb
