import React, { Component } from 'react'
import { GetUserLogin, Alerts } from '../../../helper';
export default class AccountDetails extends Component {
  constructor(){
      super();
      this.state={
          fname:null,
          lname:null,
          email:null,
          mobile_number:null,
          validationErrors:[],
          isLoading:false
      }
  }
  async componentDidMount(){
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.setState({
            user_id:userData['id'],
            fname:userData['fname'],
            lname:userData['lname'],
            email:userData['email'],
            mobile_number:userData['mobile_number'],
    })
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({[name]: value });
}
handleUpdateSubmit = async (e) => {
    e.preventDefault();
    this.setState({
        isLoading:true
    })
    const{fname, lname, email, mobile_number, user_id} = this.state;
    let data = { user_id: user_id,fname: fname, lname: lname, email: email, mobile_number: mobile_number  }
    let resp = await GetUserLogin.getCustomerUpdate(data);
    if(resp){
       if(resp.status === true){
         localStorage.setItem('userData', JSON.stringify(resp.user));   
         Alerts.alertMessage("User updated successfully.", "Nice !", "success");
         this.setState({
            validationErrors:[],
            isLoading:false
        })
       }
       if(resp.status === false){
           this.setState({ 
               validationErrors : resp.errors,
               isLoading:true
            });
       }
    }
}
    render() {
       
       const{fname, lname, email, mobile_number, validationErrors, isLoading} = this.state;
        return (
            <div className="auth-page-wrapper">
                <h3>User Information</h3>
                <div className="account-update-form">
                    <form onSubmit={this.handleUpdateSubmit}>
                        <div className="form-group row">
                          <div className="col-6">
                              <input type="text" className="form-control" placeholder="First name" name="fname" value={fname} onChange={this.handleChange}/>
                              <span className="error-help">{validationErrors['fname']}</span>
                          </div>
                          <div className="col-6">
                              <input type="text" className="form-control" placeholder="Last name" name="lname" value={lname} onChange={this.handleChange}/>
                              <span className="error-help">{validationErrors['lname']}</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-6">
                              <input type="text" className="form-control" placeholder="email" name="email" value={email} onChange={this.handleChange}/>
                              <span className="error-help">{validationErrors['email']}</span>
                          </div>
                          <div className="col-6">
                              <input type="text" className="form-control" placeholder="Mobile Number" name="mobile_number" value={mobile_number} onChange={this.handleChange}/>
                              <span className="error-help">{validationErrors['mobile_number']}</span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-6">
                              <button type="submit" className="primary-btn color-white"> { isLoading ? "Please wait...": "Update" }  </button>
                          </div>
                         
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
