const lanyard_accessories = [
    {
        text: "Button Bulldog Clip",
        image: "las1.jpg",
        value: "Button Bulldog Clip"
    },
    {
        text: "Metal Bulldog Clip",
        image: "las2.jpg",
        value: "Metal Bulldog Clip"
    },
    {
        text: "Metal Flat Swivel J Hook",
        image: "las3.jpg",
        value: "Metal Flat Swivel J Hook"
    },
    {
        text: "Metal Lobster Claw",
        image: "las4.jpg",
        value: "Metal Lobster Claw"
    },
    {
        text: "Metal Oval Hook",
        image: "las5.jpg",
        value: "Metal Oval Hook"
    },
    {
        text: "Metal Ring",
        image: "las6.jpg",
        value: "Metal Ring"
    },
    {
        text: "Metal Swivel J Hook",
        image: "las7.jpg",
        value: "Metal Swivel J Hook"
    },
    {
        text: "Plastic Phone Loop",
        image: "las8.jpg",
        value: "Plastic Phone Loop"
    },
    {
        text: "Pull Reel",
        image: "las9.jpg",
        value: "Pull Reel"
    }
]

export default lanyard_accessories;