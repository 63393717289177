import React from 'react'
const OrderEnqiryItems = ({ item }) => {
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header order-header">
                    <h3>{item.product_title}</h3>
                </div>
                <div className="card-body order-details">
                    <div className="row">
                        <div className="col-4">
                            <div className="ordered-product-image">
                                <img src={item.product_image} alt="" />
                            </div>
                        </div>
                        <div className="col-8">
                            <p><strong>Order ID</strong> : {item.enquiry_order_id} </p>
                            <p><strong>Product</strong> : {item.product_title} </p>
                            <p><strong>Quantity</strong> : {item.quantity_range} </p>
                            <p><strong>Product Base Price</strong> : $ {item.product_base_price} </p>
                            <p><strong>Print Price</strong> : $ {item.print_price} </p>
                            <p><strong>Print Type</strong> : {item.print_type} </p>
                            <p><strong>Final Price</strong> : $ {item.final_price} </p>
                            <p><strong>Delivery</strong> : {item.delivery} </p>
                            <p><strong>Leadtime</strong> : {item.leadtime} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderEnqiryItems
