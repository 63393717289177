import React, { Component } from 'react'
import { GetPages } from '../../../../helper'
import Breadcrumb from '../../../common/Breadcrumb'
import parse from 'html-react-parser';

export default class SinglePrintingMethod extends Component {
    constructor() {
        super();
        this.state = {
            methodDetails: null,
            isLoading: true,
            BreadcrumbData:[]
        }
        this.setBreadcrumb = this.setBreadcrumb.bind(this);
    }

    setBreadcrumb() {
        this.setState({
          BreadcrumbData : [
                {
                    id:1,
                    page_title:"Home",
                    page_path:"/",
                    icon:"ews ewse_gaphicsdes",
                },
                {
                    id:2,
                    page_title:"Printing Methods",
                    page_path:"#",
                    icon:"ews ewse_gaphicsdes",
                },
                {
                    id:3,
                    page_title:this.state.methodDetails.title,
                    page_path:"#",
                    icon:"ews ewse_gaphicsdes",
                }
            ]
        })
    }
    async componentDidMount() {
        const { match: { params } } = this.props;
        let resp = await GetPages.getSinglePrintingMethods(params.slug);
        if (resp) {

            this.setState({
                methodDetails: resp.printing_method,
                isLoading: false,
            }, () => {
                this.setBreadcrumb();
            });
        } else {
            this.setState({
                isLoading: false
            })
        }
    }


    
    render() {
        const { methodDetails, isLoading, BreadcrumbData } = this.state;
        document.title = methodDetails !== null ? methodDetails.meta_title : ''; 
        document.getElementsByTagName("META")[3].content=methodDetails !== null ? methodDetails.meta_description : '' ;
        document.getElementsByTagName("META")[4].content=methodDetails !== null ? methodDetails.meta_keywords : '';
        return (
            <>
                {
                    isLoading ? <div className="content-loading-wrapper">
                        <img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader" />
                    </div> : <div className="content-wrapper">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                        <div className="inner-page-header">
                            <h1> <img src={ methodDetails.icon_image } onError={e => { e.currentTarget.src = process.env.PUBLIC_URL + "../assets/img/Summary-Silkscreen.gif" }} alt="method-icon" /> { methodDetails.title }</h1>
                        </div>
                        <div className="container">
                        <div className="page-desc ck-desc">
                           {parse(methodDetails.descrition)}
                        </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}
