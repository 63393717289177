import React, { Component } from 'react'
import {Link} from "react-router-dom";
import Breadcrumb from '../../common/Breadcrumb';
import { connect } from 'react-redux';
import { removeFromCart, incrementToCart, decreaseToCart } from "../../../services/actions/cartActions";
const BreadcrumbData = [
    {
        id:1,
        page_title:"Home",
        page_path:"/",
        icon:"ews ewse_gaphicsdes",
    },
    {
        id:2,
        page_title:"Your Enquiry Cart",
        page_path:"/enquiry-cart",
        icon:"ews ewse_gaphicsdes",
    }
]
 class Cart extends Component {
    render() {
        const { cartItems } = this.props;
        return (
            <>
             <div className="content-wrapper ">
             <Breadcrumb BreadcrumbData={BreadcrumbData} />
               <div className="container mt-30">
               <div className="cart-tite">
                   <h3><i className="i-cart"></i> Your Enquiry Cart</h3>
               </div>
               { cartItems.length !== 0 ? <>
                <div className="cart-box-desktop">
                 <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th></th>
                        <th><h3>Product</h3></th>
                        <th><h3>Price/Pc</h3></th>
                        <th><h3>Remove</h3></th>
                        </tr>
                    </thead>
                    <tbody>
                  {
                        cartItems.map((item, index) => {
                           return( <tr>
                        <td>
                        <div className="product-cart-image text-center">
                        <img src={item.product_image} alt="Luminous Printing"/>
                        </div>
                        </td>
                        <td>
                        <h4><Link to="#">{item.product_title }</Link></h4>
                       <p>Quantity Required: {item.quantity }</p> 
                       <p>🎨 Custom Option: {item.print_type } </p>
                       <p>💪 Leadtime: {item.leadtime }</p>
                       <p>🚚 Delivery: {item.delivery }</p>
                        </td>
                        <td>
                            <div className="proce-in-table">
                              <h4>${item.final_price }</h4> 
                            </div>
                        </td>
                        <td>
                        <div className="cancel-in-table">
                         
                        <button type="button" className="cart-close-btn" onClick={() => this.props.removeFromCart(item.id)}>🗑️</button>
                            </div>
                        </td>
                        </tr>
                           )
                    }) 
                  }
                       
                    </tbody>
                 </table>
                 </div>
                <div className="cart-box-mobile">

                
                  {
                        cartItems.map((item, index) => {
                           return( <div className="cart-item-mobile">
                      
                        <div className="product-cart-image">
                        <img src={item.product_image} alt="Luminous Printing"/>
                        
                        </div>
                        <h4><Link to="#">{item.product_title }</Link></h4>
                        <div className="proce-in-table">
                              <h4>${item.final_price }</h4> 
                            </div>
                       <p>Quantity Required: {item.quantity }</p> 
                       <p>🎨 Custom Option: {item.print_type } </p>
                       <p>💪 Leadtime: {item.leadtime }</p>
                       <p>🚚 Delivery: {item.delivery }</p>
                       
                      
                       
                        
                        <div className="cancel-in-table-mobile">
                         
                        <button type="button" className="cart-close-btn" onClick={() => this.props.removeFromCart(item.id)}>🗑️</button>
                            </div>
                     
                        </div>
                           )
                    }) 
                  }
                  </div>
                   
                 </>
                 :<div className="not-found-box">
                     <p>Your Enquiry Cart is currently empty! Start browsing our <br/>products to enquire!</p>
                     <Link to="/" className="cart-more-product">Continue Browsing</Link>
                 </div>
                    
                }
                { cartItems.length !== 0 ? 
                 <div className="cart-bottom row">
                   <div className="col-12 text-right">
                   <Link to="/submit-enquiry" className="cart-submit-continue"> Proceed to Submit details </Link>
                   </div>
                 </div>
                 :null}
               </div>
             </div>
                
            </>
        )
    }
}
export default connect(
    (state) => ({
        cartItems: state.cart.cartItems,
    }),
    { incrementToCart, decreaseToCart, removeFromCart }
)(Cart);