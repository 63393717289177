import { BrowserRouter, Switch, Route } from "react-router-dom";

import HeaderWrapper from './wrappers/HeaderWrapper'
import FooterWrapper from './wrappers/FooterWrapper'
//pages
import Home from './components/pages/Home';
import About from './components/pages/About'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import TermCondition from './components/pages/TermCondition'
import BestSallerProduct from './components/pages/products/BestSallerProduct'
import NewArrivalProduct from './components/pages/products/NewArrivalProduct'
import PortfolioListing from './components/pages/portfolio/PortfolioListing'
import RecentProjects from './components/pages/recent-projects/RecentProjects'


import FaqPage from './components/pages/FaqPage'
import PrintingMethods from './components/pages/PrintingMethods'
import HowToOrder from './components/pages/static-pages/HowToOrder'
import DeliveryInfo from './components/pages/static-pages/DeliveryInfo'
import SizeChartInfo from './components/pages/static-pages/SizeChartInfo'
import PantoneColorChart from './components/pages/static-pages/PantoneColorChart'
import LanyardPrinting from './components/pages/static-pages/LanyardPrinting'
import LanyardPrintingEnquiry from './components/pages/static-pages/LanyardPrintingEnquiry'
import LanyardEnquiryResponce from './components/pages/static-pages/LanyardEnquiryResponce'
import Contact from './components/pages/static-pages/Contact'
import GraphicDesign from './components/pages/static-pages/GraphicDesign'
import Posts from './components/pages/posts/Posts'
import PostSingle from './components/pages/posts/PostSingle'











//category pages
import CategoryProductLayout from './components/Layout/CategoryProductLayout'
import Category from './components/pages/categories/Category';
import CategoryProduct from './components/pages/categories/CategoryProduct'
import CategoryListing from './components/pages/categories/CategoryListing'

//Notification
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

//scroll to top
import ScrollToTop from './components/ScrollToTop'


//Product, cart, enquries
import CartWrapper from './wrappers/CartWrapper'
import ProductDetails from './components/pages/products/ProductDetails'
import NewProductDetails from './components/pages/products/NewProductDetails'


import SubmitEnquiry from './components/pages/enquiries/SubmitEnquiry'
import Protected from './components/protected/Protected'
import AuthWrapper from './components/auth/AuthWrapper'
import EnquiryResponce from './components/pages/enquiries/EnquiryResponce'
import SinglePrintingMethod from './components/pages/page-sections/printing-methods/SinglePrintingMethod'


//user Account
import DashboardLayout from './components/Layout/DashboardLayout'
import AccountDetails from './components/auth/account/AccountDetails'
import UserEnquiries from './components/auth/account/UserEnquiries'
import ChangePassword from './components/auth/account/ChangePassword'

import NotFound from './components/pages/NotFound'
function App() {
  return (
    <>
      <ReactNotification />
      <BrowserRouter>
        <HeaderWrapper />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact-us" component={Contact} />
          
          <Route path="/recent-projects" component={PortfolioListing} />

          <Route path="/recent-projects-2" component={RecentProjects} />

          
          {/* <Route path="/product/:category_slug/:slug" component={ProductDetails} /> */}
          {/* <Route path="/product/:parent_cat?/:category_slug/:slug" component={ProductDetails} /> */}
          <Route path="/product/:parent_cat?/:category_slug/:slug" component={ProductDetails} />
          
          <Route path="/printing-method/:slug" component={SinglePrintingMethod} />
          <Route path="/tag/best-sallers" component={BestSallerProduct} />
          <Route path="/tag/new-arrivals" component={NewArrivalProduct} />
          <Route path="/term-conditions" component={TermCondition} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/faqs" component={FaqPage} />
          <Route path="/printing-methods" component={PrintingMethods} />
          <Route path="/how-to-order" component={HowToOrder} />
          <Route path="/delivery-information" component={DeliveryInfo} />
          <Route path="/size-chart-information" component={SizeChartInfo} />
          <Route path="/pantone-color-chart" component={PantoneColorChart} />
          <Route path="/lanyard-printing" component={LanyardPrinting} />
          <Route path="/lanyard-printing-enquiry" component={LanyardPrintingEnquiry} />
          <Route path="/lanyard-enquiry-responce" component={LanyardEnquiryResponce} />
          <Route path="/graphic-design-service" component={GraphicDesign} />
          <Route path="/blogs" component={Posts} />
          <Route path="/blog/:slug" component={PostSingle} />
          
          
          
          
          
          
          
          <Route path="/enquiry-cart" component={CartWrapper} />
          <Route path="/login" component={AuthWrapper} />
          <Route path="/category/apparels" component={CategoryListing} />
          <Route path="/category/corporate-gifts" component={CategoryListing} />
          <Protected path="/submit-enquiry" component={SubmitEnquiry} />
          <Protected path="/enquiry-responce" component={EnquiryResponce} />
          <Route path={["/user/dashboard","/user/enquiries","/user/change-password"]}>
            <DashboardLayout>
              <Switch>
                <Protected path='/user/dashboard' exact component={AccountDetails} />
                <Protected path='/user/enquiries' exact component={UserEnquiries} />
                <Protected path='/user/change-password' exact component={ChangePassword} />
              </Switch>
            </DashboardLayout>
          </Route>
          <Route path={["/products","/category/:slug", "/category/:parent_cat/:slug"]}>
            <CategoryProductLayout>
              <Route path="/products" exact component={Category} />
              <Route path="/category/:slug" exact component={CategoryProduct} />
              <Route path="/category/:parent_cat/:slug" exact component={CategoryProduct} />
            </CategoryProductLayout>
          </Route>
         
          <Route path="/not-found" component={NotFound} />
          <Route exact component={NotFound} />
        </Switch>
        <FooterWrapper />
      </BrowserRouter>
    </>
  );
}

export default App;
