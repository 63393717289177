import React, { Component } from 'react'
import Modal from '../../common/modal/Modal'
import { Alerts, GetCorporateEnquiries } from '../../../helper';
const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const contactRegex = RegExp(
    /^[0-9\b]+$/
);
const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};
export default class EnquiryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_name: props.product.title,
            product_id: props.product.id,
            name: null,
            email: null,
            contact: null,
            subject: null,
            enquiry_msg: null,
            isSubmitted: false,
            respData: '',
            isLoading:false,
            formErrors: {
                name: "",
                email: "",
                contact: "",
                subject: "",
                enquiry_msg: ""
            },


        }
    }
    onModalClose = () =>{
        this.setState({
            isSubmitted: false,
            product_name: this.props.product.title,
            product_id: this.props.product.id,
            name: null,
            email: null,
            contact: null,
            subject: null,
            enquiry_msg: null
        })
        this.props.onClose();
    }
    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };

        switch (name) {
            case "name":
                formErrors.name = value.length <= 0 ? "Please enter your name." : "";
                break;
            case "email":
                formErrors.email = value.length <= 0 ? "Please enter your email." :
                    formErrors.email = emailRegex.test(value)
                        ? ""
                        : "Invalid email address";
                break;
            case "contact":
                formErrors.contact = value.length <= 0 ? "Please enter your contact number." : formErrors.contact = contactRegex.test(value)
                    ? ""
                    : "Invalid contact number";
                break;
            case "subject":
                formErrors.subject = value.length <= 0 ? "Please enter your subject." : "";
                break;
            case "enquiry_msg":
                formErrors.enquiry_msg = value.length <= 0 ? "Please enter your enquiry message." : "";
                break;
            default:
                break;
        }
        this.setState({ formErrors, [name]: value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true,
        })
        let { name, email, contact, subject, enquiry_msg, product_name, product_id } = this.state;
        let data = { name: name, email: email, contact: contact, subject: subject, enquiry_msg: enquiry_msg, product_id: product_id, product_title: product_name }
        if (formValid(this.state)) {
            let resp = await GetCorporateEnquiries.submitCorporateEnquiry(data);
            if (resp) {
                if (resp.status === true) {
                    this.setState({
                        respData: resp.enquiryDetails,
                        isSubmitted: true,
                        isLoading: false
                    })

                }
            }
        } else {
            this.setState({
                isLoading: false
            })
            Alerts.alertMessage("All feilds are required.", "Input Error", "danger");
        }

    }


    render() {

        const { name, email, contact, subject, enquiry_msg, formErrors, isSubmitted, respData, isLoading } = this.state;
        return (

            <>
                {this.props.show ? <div className="back-drop" onClick={this.onModalClose}></div> : null}

                <Modal show={this.props.show}>
                    <div className="modal-main enquiry-modal">
                        <div className="modal-header">
                            <p>Enquiry Form</p>
                            <span className="close-modal-btn" onClick={this.onModalClose}><i className="i-plus"></i></span>
                        </div>
                        <div className="modal-content">
                            <div className="modal-body">

                                {
                                    isSubmitted ? <div className="form-resposonce">
                                        <h1>Thank You</h1>
                                        <p>Your enquiry for {this.props.product.title} has been successfully submitted. </p>
                                        <p>Enquiry ID :  {respData.id}</p>
                                        <p>I will contact you shorty for enquiry confirmation. </p>
                                    </div> : <>
                                        <p style={{ margin: '0px' }}>You are enquiry for {this.props.product.title}</p>
                                        <div className="form-wrapper">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Name*" name="name" value={name} onChange={this.handleChange} />
                                                    {formErrors.name.length > 0 && (
                                                        <span className="errorMessage">{formErrors.name}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Email*" name="email" value={email} onChange={this.handleChange} />
                                                    {formErrors.email.length > 0 && (
                                                        <span className="errorMessage">{formErrors.email}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Mobile Number*" name="contact" value={contact} onChange={this.handleChange} />
                                                    {formErrors.contact.length > 0 && (
                                                        <span className="errorMessage">{formErrors.contact}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Subject*" name="subject" value={subject} onChange={this.handleChange} />
                                                    {formErrors.subject.length > 0 && (
                                                        <span className="errorMessage">{formErrors.subject}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <textarea className="form-control" placeholder="Enquiry*" name="enquiry_msg" style={{ height: '100px' }} onChange={this.handleChange}>{enquiry_msg}</textarea>
                                                    {formErrors.enquiry_msg.length > 0 && (
                                                        <span className="errorMessage">{formErrors.enquiry_msg}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary btn-block">{ isLoading ? 'Sending...' : 'Submit'  }</button>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }


                            </div>
                        </div>
                    </div>
                </Modal>
            </>

        )
    }
}
