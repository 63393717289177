const lanyard_pvc_cardholder_options = [
    {
        text: "None",
        image: "ph1.jpg",
        value: "None"
    },
    {
        text: "ID03 PVC Card Holder",
        image: "ph2.jpg",
        value: "ID03 PVC Card Holder"
    },
    {
        text: "ID04 PVC Card Holder",
        image: "ph3.jpg",
        value: "ID04 PVC Card Holder"
    },
    {
        text: "ID05 PVC Card Holder",
        image: "ph4.jpg",
        value: "ID05 PVC Card Holder"
    },
    {
        text: "ID06 PVC Card Holder",
        image: "ph5.jpg",
        value: "ID06 PVC Card Holder"
    },
    {
        text: "ID07 PU Leather Card Holder",
        image: "ph6.jpg",
        value: "ID07 PU Leather Card Holder"
    },
    {
        text: "ID08 PU Leather Card Holder",
        image: "ph7.jpg",
        value: "ID08 PU Leather Card Holder"
    },
    {
        text: "ID09 Plastic Case Card Holder",
        image: "ph8.jpg",
        value: "ID09 Plastic Case Card Holder"
    }
]
export default lanyard_pvc_cardholder_options;