import React from 'react'
import { Link } from 'react-router-dom';
const CategoryProductList = ({item}) => {
    return (
        <>
            <div className="col-2 col-sm-6">
              <Link to={'/category/' + item.slug}>
              <div className="category-box text-center">
                <div className="category-image">
                <img src={item.cat_icon_image} alt="Luminous Printing"/>
                </div>
                <div className="category-title">
                 <h4>{item.title}</h4>
                </div>
              </div>
              </Link>
            </div>
            
        </>
    )
}

export default CategoryProductList
