import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import ProductItem from './ProductItem'
import { GetProducts } from '../../../helper'
const BreadcrumbData = [
    {
        id:1,
        page_title:"Home",
        page_path:"/",
        icon:"ews ewse_gaphicsdes",
    },
    {
        id:2,
        page_title:"New Arrivals",
        page_path:"/tag/new-arrivals",
        icon:"ews ewse_gaphicsdes",
    }
]
export default class NewArrivalProduct extends Component {
    constructor() {
        super();
        this.state = {
            products: [],
            isLoading:true,
            limit: 8
        }
        this.onLoadMore = this.onLoadMore.bind(this);
    }
    onLoadMore() {
        this.setState({
            limit: this.state.limit + 8
        });
    }
    async componentDidMount() {
        let resp = await GetProducts.getNewArraivalProducts();
        if(resp){
            this.setState({
                products: resp.products,
                isLoading:false
            })
        }else{
            this.setState({
             isLoading:false
         }) 
        }
    }
    render() {
        const { products, isLoading } = this.state;
        const showProduct = products.slice(0,this.state.limit);
         console.log(showProduct);
        return (
            <>
                <div className="content-wrapper">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="inner-page-header">
                        <h1>New Arrivals Products</h1>
                    </div>

                    <div className="container">
                    <div className="page-content">
                    <div className="row">
                    {
                        isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>

                        :<><div className="row">
                            {
                            showProduct.map((item, index) => {
                                    return <ProductItem key={index} item={item} />
                                }) 
                            }


                        </div>
                        <div className="view-more-transparent">
                        {showProduct.length === products.length ? null : <button onClick={this.onLoadMore}>View More Polo T‑shirts</button>}
                        </div>
                        </>
                    }
                    </div>
                    </div>
                    </div>
                </div>
            </>
        )
    }
}
