import React, { Component } from 'react'
import parse from 'html-react-parser'

const Arrow = ({ direction, clickFunction, glyph }) => (
	<div 
		className={ `slide-arrow ${direction}` } 
		onClick={ clickFunction }>
        <i className={ glyph } ></i>
		
	</div>
);
const LayoutOneSlide = ({ url, captionText, descriptionText }) => {
	return (
		<>
		<p className="text-center">{ parse(descriptionText) }</p>
        <div className="image-slide mt-20">
            <img src={url} alt=""/>
            <div className="carousel-content">
                <h3>{captionText}</h3>
            </div>
        </div>
		</>
	);
}

const LayoutTwoSlide = ({ profileImage,profileName,review }) => {
	return (
        <div className="image-slide layout-two bg-google">
                <p>{review}</p>
                <div className="user-profile">
                <img src={profileImage} alt=""/>  
                <p>{profileName}</p>
                </div>
                
        </div>
	);
}

const LayoutThreeSlide = ({ title,description,clientName }) => {
	return (
        <div className="image-slide layout-three">
                 <h3>{title}</h3>
                 <p>{description}</p>
				 <p>{clientName}</p>
              
                
        </div>
	);
}


export default class Carousel extends Component {
    constructor (props) {
		super(props);
		
		this.state = {
			currentImageIndex: 0
		};
		
		this.nextSlide = this.nextSlide.bind(this);
		this.previousSlide = this.previousSlide.bind(this);
       this.bulletSlide = this.bulletSlide.bind(this);
	}
	
	previousSlide () {
		const lastIndex = this.props.imgUrls.length - 1;
		const { currentImageIndex } = this.state;
		const shouldResetIndex = currentImageIndex === 0;
		const index =  shouldResetIndex ? lastIndex : currentImageIndex - 1;
		
		this.setState({
			currentImageIndex: index
		});
       
	}
	
	nextSlide () {
		const lastIndex = this.props.imgUrls.length - 1;
		const { currentImageIndex } = this.state;
		const shouldResetIndex = currentImageIndex === lastIndex;
		const index =  shouldResetIndex ? 0 : currentImageIndex + 1;

		this.setState({
			currentImageIndex: index
		});
	}
    bulletSlide (index) {
		this.setState({
			currentImageIndex: index
		});
	}

   
    render() {
        return (
            <>
               <div className="carousel">
				<Arrow direction="left" clickFunction={ this.previousSlide } glyph="i-angle-left" />
                {
                    this.props.layout === "layoutOne" ? <LayoutOneSlide url={ this.props.imgUrls[this.state.currentImageIndex].thumbnail_image } captionText={ this.props.imgUrls[this.state.currentImageIndex].title } descriptionText={this.props.imgUrls[this.state.currentImageIndex].description}/>
                    :this.props.layout === "layoutTwo" ?<LayoutTwoSlide profileImage={ this.props.imgUrls[this.state.currentImageIndex].profile_image } profileName={ this.props.imgUrls[this.state.currentImageIndex].profile_name } review={ this.props.imgUrls[this.state.currentImageIndex].review }/>
					:<LayoutThreeSlide title={ this.props.imgUrls[this.state.currentImageIndex].title } description={ this.props.imgUrls[this.state.currentImageIndex].description } clientName={ this.props.imgUrls[this.state.currentImageIndex].clientName }/>
					
					
                }
				
				<Arrow direction="right" clickFunction={ this.nextSlide } glyph="i-angle-right" />
                <ul className="carousel-control">
                {this.props.imgUrls.map((item, i) => (
                 <li key={i} onClick={e => this.bulletSlide(i)} className={`carousel-dot ${this.state.currentImageIndex === i ? 'active' : ''}`}><span></span></li>
                ))}
                </ul>
			</div> 
            </>
        )
    }
}
