import React, { Component } from 'react'
import { Alerts } from '../../helper';
import GoogleLogin from 'react-google-login';
const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};
export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            isLoading: false,
            formErrors: {
                email: "",
                password: ""
            }
        };
    }
    onSuccess = async (res) => {
        console.log('[logged in success] currentUser :', res);
        let data = { provider_id: res.googleId, id_token: res.tokenId }

        this.props.onGoogleLogin(data);

    }

    onFailure = (res) => {
        console.log('[logged in failed] res :', res);
    }
    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };

        switch (name) {
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;
            case "password":
                formErrors.password =
                    value.length < 6 ? "minimum 6 characaters required" : "";
                break;
            default:
                break;
        }
        this.setState({ formErrors, [name]: value });
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true,
        })
        let { email, password } = this.state;
        let data = { email: email, password: password }
        if (formValid(this.state)) {
            this.props.onLogin(data);
        } else {
            this.setState({
                isLoading: false,
            })
            Alerts.alertMessage("Please enter your Login credential.", "Input Error", "danger");
        }

    }

    render() {
        let { email, password, isLoading, formErrors } = this.state;
        return (

            <div className="form-wrapper">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type="email" className="form-control" name="email" value={email} onChange={this.handleChange} placeholder="Email" />
                        {formErrors.email.length > 0 && (
                            <span className="errorMessage">{formErrors.email}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <input className="form-control" name="password" type="password" value={password} onChange={this.handleChange} placeholder="Password" />
                        {formErrors.password.length > 0 && (
                            <span className="errorMessage">{formErrors.password}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block sbt-btn-loader" onClick={this.handleSubmit}>{isLoading ? 'Please wait...' : 'Login'}  </button>
                    </div>
                </form>
                <div className="social-login">
                    <p>-------------OR -------------</p>
                    <GoogleLogin
                        clientId="815778410908-knq2lpc8aok2atttvr8l72io9h7hq052.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={this.onSuccess}
                        onFailure={this.onFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>

            </div>

        )
    }
}

export default Login
