import React from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';
const PrintingMethodsItems = ({item}) => {
    return (
        <div className="col-3 col-sm-12">
        <div className="service-box-2">
            <div className="service-image">
             <img className="hover-zoom" src={item.thumbnail_image} alt="Luminous Printing"/>
            </div>
            <div className="service-content">
            <h3>{item.title}</h3>
            {parse(item.excerpt)}  
             <Link to={`/printing-method/${item.slug}`}>Read More<i className="i-angle-right"></i></Link>
            </div>
        </div>
    </div>
    )
}
export default PrintingMethodsItems