const lanyard_strap_size_options = [
    {
        text: "10mm lanyard",
        image: "lss1.jpg",
        value: "10mm lanyard"
    },
    {
        text: "15mm lanyard",
        image: "lss2.jpg",
        value: "15mm lanyard"
    },
    {
        text: "20mm lanyard",
        image: "lss3.jpg",
        value: "20mm lanyard"
    },
    {
        text: "25mm lanyard",
        image: "lss4.jpg",
        value: "25mm lanyard"
    }
]
export default lanyard_strap_size_options;