import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Carousel from '../../common/carousel/Carousel'
import { GetPages } from '../../../helper'

// const recentProjects = [
// 	{
// 		"id": 1,
// 		"title": "Twitter Singapore - Embroidery",
// 		"img_src" : "../assets/img/project1.png"
// 	},
// 	{
// 		"id": 2,
// 		"title": "Twitter Singapore - Embroidery",
// 		"img_src" : "../assets/img/dummy-bg.jpg"
// 	},
//     {
// 		"id": 3,
// 		"title": "Twitter Singapore - Embroidery",
// 		"img_src" : "../assets/img/project1.png"
// 	}
// ];
const googleReviews = [
    {
        "id": 1,
        "profile_name": "Vincent Teng",
        "profile_image": "../assets/img/v.png",
        "review": "I have my shop polo t-shirt done with Luminous Printing. Excellent Services."
    },
    {
        "id": 2,
        "profile_name": "Nai Seira",
        "profile_image": "../assets/img/s.png",
        "review": "It was my first time printing shirts and Wyrenn helped made the process so smooth and easy. He is also very patient, responsive and helpful in assisting with my many many inquiries. Such excellent service!! Also, not forgetting the affordable price he gave even though I was only printing a very small quantity. Will definitely order again in the future!!"
    },
    {
        "id": 3,
        "profile_name": "YSH jr",
        "profile_image": "../assets/img/y.png",
        "review": "First time ordering and really like how the polo T-shirts turned out. Material is soft and comfy! Wyrenn is very professional, helped to recommend which colour looks good with embroidery and speed up my order just in time for open day! Will definitely order again in the future 👍 Highly recommend Luminous Printing!"
    },
];
export default class ServiceOverview extends Component {
    constructor() {
        super();
        this.state = {
            recentProjects: [],
            isLoading: true
        }
    }
    async componentDidMount() {
        let resp = await GetPages.getHomepageRecentProjects();
        if (resp) {
            this.setState({
                recentProjects: resp.home_recent_projects,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        const { recentProjects, isLoading } = this.state;
        console.log(recentProjects.length);
        return (
            <>
                <div className="service-overview-section">
                    <div className="container full-width-container">
                        <div className="row">
                            <div className="col-6 col-sm-12">
                                <div className="service-overview-box-1">
                                    <div className="section-title text-center">
                                        <h2>Recent Projects</h2>
                                        <div className="border-bottom-line">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                      
                                    </div>
                                    <div className="recent-project-carousel">
                                        {
                                            isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader" /></div>

                                                :
                                                recentProjects.length === 0 ? <div className="data-warning-box">
                                                    <img src={process.env.PUBLIC_URL + "../assets/img/iconWarning.png"} alt="product-loader" />
                                                    <p className="data-warning">  No data available</p>
                                                </div> : <><Carousel imgUrls={recentProjects} layout="layoutOne" />
                                                    <div className="text-center">
                                                        <Link to="/recent-projects-2" className="info-bg view-more normal-btn">See All Recent Projects</Link>
                                                    </div>
                                                </>
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className="col-6 col-sm-12">
                                <div className="service-overview-box-2">
                                    <div className="section-title text-center">
                                        <h2>Google Reviews</h2>
                                        <div className="border-bottom-line">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                        <p>Custom T-Shirt Printing & Apparel customisation is our Bread & Butter. It’s basically what we do day in, day out. Check out our custom printed tees from past customers.</p>
                                    </div>
                                    <div className="google-reviews-box">
                                        <Carousel imgUrls={googleReviews} layout="layoutTwo" />
                                    </div>
                                    <div className="text-center">
                                        <Link to="/" className="info-bg view-more normal-btn">See All Reviews</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
