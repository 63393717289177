import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAllRecentProjects = async () => {
    try {
        let result = await api.get(Apis.GetAllRecentProjects);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const exportedObject = {
    getAllRecentProjects
};
export default exportedObject;