import api from '../../ApiConfig';
import { Apis } from '../../config';

// import { NotificationManager } from 'react-notifications';

const getUserLogin = async (data) => {
    try {
        let result = await api.post(Apis.UserLogin, data);
        if (result.data.error) {
            // NotificationManager.error(result.data.error);
            console.log(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getUserRegister = async (data) => {
    try {
        let result = await api.post(Apis.GetUserRegsiter, data);
        // if (result.data.error) {
        //     // NotificationManager.error(result.data.error);
        //     return null;
        // }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const authenticate = async (data, email) => {
    if (typeof window !== "undefined") {
        sessionStorage.setItem('_sid', data)
        sessionStorage.setItem('email', email)
        setTimeout(
            function () {
                window.location.reload();
            },
            1000
        );
    }
};

const getCustomerDetail = async (token) => {
    console.log(token);
    try {
        let result = await api.get(Apis.GetCustomerDetails,{
            headers: {Authorization: `Bearer ${token}` }
        });
        if (result.data.error) {
            // NotificationManager.error(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCustomerUpdate = async (data) => {
    try {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        let userToken = null;
        if (isAuthenticated && isAuthenticated === 'true') {
            userToken = JSON.parse(localStorage.getItem('userToken'))
        }
      
        let result = await api.post(Apis.UpdateCustomerDetails,data,
            {
                headers: {Authorization: `Bearer ${userToken}` }
            });
        // if (result.data) {
        //     console.log(result.data);
        //     // NotificationManager.error(result.data.error);
        //     return null;
        // }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getPasswordUpdate = async (data) => {
    try {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        let userToken = null;
        if (isAuthenticated && isAuthenticated === 'true') {
            userToken = JSON.parse(localStorage.getItem('userToken'))
        }
      
        let result = await api.post(Apis.UpdatePassword,data,
            {
                headers: {Authorization: `Bearer ${userToken}` }
            });
        // if (result.data) {
        //     console.log(result.data);
        //     // NotificationManager.error(result.data.error);
        //     return null;
        // }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const authenticateByCart = async (token, email) => {
    if (typeof window !== "undefined") {
       sessionStorage.setItem('_sid', token)
       sessionStorage.setItem('email', email)
        setTimeout(
            function () {
                window.location.href = "/checkout";
            },
            1000
        );
    } else {
        // NotificationManager.error("Please check your login", "Input Error");
    }
};


    const logout = async () => {
        try {
            const isAuthenticated = localStorage.getItem('isAuthenticated');
            let userToken = null;
            if (isAuthenticated && isAuthenticated === 'true') {
                userToken = JSON.parse(localStorage.getItem('userToken'))
            }
          
            let result = await api.get(Apis.GetUserLogout,
                {
                    headers: {Authorization: `Bearer ${userToken}` }
                });
            // if (result.data) {
            //     console.log(result.data);
            //     // NotificationManager.error(result.data.error);
            //     return null;
            // }
            return result.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };


const isAuthenticate = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    return sessionStorage.getItem('_sid');
};

const getSocialLogin = async (data) => {
    try {
        let result = await api.post(Apis.UserGoogleLogin, data);
        if (result.data.error) {
            // NotificationManager.error(result.data.error);
            console.log(result.data.error);
            return null;
        }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const exportedObject = {
    getUserLogin,
    getSocialLogin,
    authenticate,
    isAuthenticate,
    authenticateByCart,
    getUserRegister,
    getCustomerDetail,
    getCustomerUpdate,
    getPasswordUpdate,
    logout,
};
export default exportedObject;