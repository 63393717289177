import React, { Component } from 'react'
import { GetPosts } from '../../../helper'
import Breadcrumb from '../../common/Breadcrumb'
import parse from 'html-react-parser'
export default class PostSingle extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            isLatestPostLoading: true,
            latestPosts: null,
            postDetails: null
        }
    }

    getPostData = async (slug) =>{
       
        let resp = await GetPosts.getSinglePost(slug);
        let resp2 = await GetPosts.getLatestPost();
        if (resp) {
            if (resp.post === null) {
                this.props.history.push('/not-found');
            } else {
                this.setState({
                    postDetails: resp.post,
                    isLoading: false
                })
            }
        }
        if (resp2) {
          
            this.setState({
                latestPosts: resp2.posts,
                isLatestPostLoading: false
            })
        }
    }


     componentDidMount() {
        let slug = this.props.match.params.slug;
      this.getPostData(slug);
    }

    openNewPost = (slug) =>{
        this.props.history.push(`/blog/${slug}`);
        this.getPostData(slug);
    }
    render() {
        const { postDetails, isLoading, latestPosts, isLatestPostLoading } = this.state;

        document.title = postDetails !== null ? postDetails.meta_title : null;
        document.getElementsByTagName("META")[3].content = postDetails !== null ? postDetails.meta_desc : null;
        document.getElementsByTagName("META")[4].content = postDetails !== null ? postDetails.meta_keywords : null;
        const BreadcrumbData = [
            {
                id: 1,
                page_title: "Home",
                page_path: "/",
                icon: "ews ewse_gaphicsdes",
            },
            {
                id: 2,
                page_title: "Blogs",
                page_path: "/blogs",
                icon: "ews ewse_gaphicsdes",
            },
            {
                id: 3,
                page_title: postDetails !== null ? postDetails.title : null,
                page_path: "#",
                icon: "ews ewse_gaphicsdes",
            }
        ]
        return (
            <>
                <div className="content-wrapper">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="container">
                        <div className="page-desc">
                            {
                                isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div> :
                                    <div className="post-details-wrapper">
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="post-title">
                                                    <h2>{postDetails.title}</h2>
                                                    <p>{postDetails.created_at} | Posted By : {postDetails.author_name}</p>
                                                    <div className="post-description">
                                                        {parse(postDetails.description)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="post-sidebar">
                                                    <div className="post-sidebar-header">
                                                        <h3>Latest Post</h3>
                                                    </div>
                                                    {
                                                        isLatestPostLoading ? "Data Loading.." :
                                                            <ul>
                                                                {
                                                                    latestPosts.map((item, index) => {
                                                                        return <li key={index}> <button onClick={()=>this.openNewPost(item.slug)}>{ item.title}</button> </li>
                                                                    })
                                                                }

                                                            </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
