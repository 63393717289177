import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Delivery Information",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class DeliveryInfo extends Component {
    render() {
        document.title = 'Delivery Information'; 
        document.getElementsByTagName("META")[3].content='Delivery Information';
        document.getElementsByTagName("META")[4].content='Delivery Information';
        return (
            <>
                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="container">
                        <div className="page-desc">
                            <div className="section-title text-center">
                                <h2>Delivery Information</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                               
                            </div>
                            <div className="container">
                           <h3> Delivery Method</h3>

<p>Luminous Printing does its utmost best to facilitate all of our customers’ delivery requests. If you wish to have your order delivered using any other method that isn’t listed below, feel free to contact us!</p>

 

<h3>1- Doorstep Delivery Services</h3>
<p>We also offer doorstep delivery service, regardless of the order sizes or quantity. You may schedule and check the rate of your delivery with our customer representative. We will ensure that the tshirt or corporate gifts able to reach your doorstep according to your order scheduling.</p>



<p>Courier delivery will be charged to the customer at the following rates:
Doorstep Delivery within 2 days from $12.00 onward subject to location
</p>
<p>Via : LALAMOVE or GRAB<br/>Express Delivery within 2 hours from $15.00 onward subject to location</p>
<p>For free delivery is only applicable for certain items with total quantity at least 500pcs and above.</p>
 
<h3>2- Self-Collection</h3>
<p>Self-Collection at our production is 100% free of charge. Operating hour is on weekday 9am-6pm. </p>
<p>
We also can arrange self-collection at our sales office located at Hougang. It is completely free of charge. Hence, please confirm with our salesperson regard the correct address and timing before dropping by. An whatsapp or email confirmation will be given 2-3 days before. If you wish you collect your completed orders on a weekend, please check with our sales representative for the arrangement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
