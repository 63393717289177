const lanyard_safety_buckle_options = [
    {
        text: "None",
        image: "sb1.jpg",
        value: "None"
    },
    {
        text: "Safety breakaway A",
        image: "sb2.jpg",
        value: "Safety breakaway A"
    },
    {
        text: "Safety breakaway B",
        image: "sb3.jpg",
        value: "Safety breakaway B"
    },
    {
        text: "Safety buckles",
        image: "sb4.jpg",
        value: "Safety buckles"
    },
]
export default lanyard_safety_buckle_options;