import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Graphic Design",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class GraphicDesign extends Component {
    render() {
        document.title = 'Graphic Design'; 
        document.getElementsByTagName("META")[3].content='Graphic Design';
        document.getElementsByTagName("META")[4].content='Graphic Design';
        return (
            <>
                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />


                    <div className="container">

                        <div className="page-desc">
                            <div className="section-title text-center">
                                <h2>Graphic Design</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                               
                            </div>
                          
                            <div className="container">
                           <h3> Graphic Design Service (tshirt and corporate gift)</h3>
<p>Graphic Design Service is also 1 of the service that provided by Luminous printing , we are not just a t-shirt printing & corporate gift supplier. We also provide 1 stop marketing collateral graphic design service such as Menu, Packaging, Catalogue, Brochure, Flyer, Poster, Business Name Card, Badge, Magazine Ads, Voucher, Banner/Pull-up Banner and etc. Email direct marketing (EDM) & Web Banner are part of our services as well.</p>

<p>Marketing collateral act as a very important role nowadays. It acts as a branding of an organization, provide informative content to ensure that the design able to attract a new potential customer toward a non well-known company.</p>

<p>Our graphic designer has more 10 years experience in this industry. Just give us your idea, name your theme or concept you desire for your design or any details you want to include. Our design professions will come up with a design based on your guidelines. With a combination of the creative idea and the execution of your creative imagination, we ensure your design is new and unique.</p>

<p>Getting the right kind of creative services is probably the most challenging task for an entrepreneur. This is where Luminous Printing is.</p>

<h4>What is the Costing?</h4>
<p>The exact costing of a design depends on customer requirements and subject to the complexity of the project. We guaranteed that our graphic design service is the cheapest among all the graphic design service company in Singapore. Our pricing is 100% guarantee with no extra charges or hidden cost. The price comparison is always welcome, share your budget with us if necessary.</p>

<p>We promised our customers that we’ve provided a high-quality print at affordable prices by implementing cost-cutting technologies and processes internally.</p>

<h4>Having a design or artwork problem?</h4>
<p>Do not have any idea on what to print? Resolution of your artwork is not high enough or not matching the requirements? Then it is time to engage with us for our one-stop solution service.</p>

<p>If you have select Luminous Printing as your printing company, when you are ordering t-shirt printing or corporate gift printing with us, whenever you having any design issue, our professional designer will provide the best solution to solve your problem.</p>

<p>Drop your enquiry email to us @ sales.luminousprinting@gmail.com  or WhatsApp 91195104 is always welcome. Get your free consultation today!</p>

<p>If you just need a simple free artwork trace service for you t shirt printing or corporate gift printing , visit here.</p>

<p>You may also access to <a href="https://www.customink.com/ndx/#/ad/gate" target="_blank" rel="noreferrer">Customink</a> of design your own t shirt for the printing.</p>

<div className="graphic-service-tab">

                            <Tabs>
                                <TabList>
                                    <Tab>Work Process</Tab>
                                    <Tab>Artwork Retrace Services</Tab>
                                </TabList>

                                <TabPanel>
                                 <div className="tab-section-content">
                                 <h3>How We Work and come out with a design!</h3>
<p>Please find below our workflow of graphic design services</p>
                                 </div>
                                 <div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/Work-Flow.jpg"} alt="product-loader" style={{ width:'auto' }}/>
</div>
                                 <h3>Printing Service</h3>
                                 <p>We are also one stop solution printing company. If you would like us to have your marketing collateral printed out and yes, you’ve found us.</p>

                                 <p>As a result, time-saving and minimise your workload, always come to Luminous Printing. A trusted tshirt printing, corporate gift printing and also a graphic design services company in Singapore.</p>
                                   
                                </TabPanel>
                                <TabPanel>
                                <div className="tab-section-content">
                                <h3>Artwork retrace service</h3>
                                <p>Having a low-resolution artwork? Or you have found the artwork from google or other resources you desire but it is blurred? Having jagged wording or sentences? Well, you are not the only one out there. We found out most of the customers have no relevant knowledge in the printing industry, most of them do not know how to make their artwork to have a higher resolution to meet the printing requirements either.</p>

                                <p>High-resolution artwork is very important in the printing industry, no matter where you go or which vendor you came across to, they will still request the same from you.</p>

                                <p>Thus, now you may Say Hi with a big smile to our NEW free tracing services.</p>

                                <p>Luminous Printing Is now providing free Artwork Retrace Service for tracing the simple, low-resolution artwork or logo!</p>

                                <p>However, if your artwork is very complicated with much details, fine shape or colorful image, the artwork tracing will be charge according to the artworks’ complexity.</p>

                                <p>If you are looking to create a new design, design charges will be incurred accordingly. Do not worry about the costing, Luminous Printing always provides the economical & cheap services to you, our valuable customer. With the cost that you paid, you will own the original artwork file for your future usage.</p>

                                <p>So from now on, say Good Byes to the misery of low-resolution artwork or logo.</p>

                                <p>If you are looking for a designer to design your artwork, kindly find more information here.</p>

                                <p>You may also access to Customink of design your own t shirt for the printing. In this website, you can design your own artwork with the idea suggested by them. It is useful for those customers who has totally no idea of what to print on the tshirt.</p>

                                <p>Below are some of the free trace and paid trace samples pictures.</p>

                                <div className="treace-box">
                                    <div className="row">
                                        <div className="col-6">
                                        <h3>Free Trace</h3>
                                        <div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/Free-Trace-B.jpg"} alt="product-loader" />
</div>
                                        </div>
                                        <div className="col-6">
                                        <h3>Paid Trace</h3>
                                        <div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/Paid-Trace-B.jpg"} alt="product-loader" />
</div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </TabPanel>
                            </Tabs>
</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

