import axios from 'axios';
import { API_URL } from './config';
import { setup } from 'axios-cache-adapter'
// Create `axios-cache-adapter` instance

  
// export default axios.create({
//     baseURL : API_URL,
//     headers : {
//         "Content-Type":"application/json",
//     }
// })
const api = setup({
    baseURL : API_URL,
  headers : {
      "Content-Type":"application/json",
  },
  cache: {
    maxAge: 15 * 60 * 1000
  }
})
export default api;