import React, { Component } from 'react'
import { Link, withRouter  } from 'react-router-dom';
import {GetCategories} from '../../../helper'
 class CategoryMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      CategoryMenus:[],
      windowlocation : window.location.pathname,
    }
    this.handleTabClick = this.handleTabClick.bind(this);
    this.updateTabs = this.updateTabs.bind(this);
  }

  updateTabs(id) {
    let CategoryMenus = this.state.CategoryMenus;
    let newtabs = CategoryMenus.map((tab, index) => {
      if (tab.id === id) {
        if (tab.active === true) {
          tab.active = false;
        } else {
        
          tab.active = true;
        }
      } else {
        tab.active = false;
      }
      return tab;
    });
    return newtabs;
  }

  handleTabClick(id) {
    // let currentLocation = localStorage.getItem('currentLocation');
    // let newLoacation = this.props.history.location.pathname;
    this.setState({ CategoryMenus: this.updateTabs(id) });
   
   
  }
  async componentDidMount(){
    
    let resp = await GetCategories.getAllCategories();
    if(resp){
        this.setState({
          CategoryMenus: resp.categories,
            isLoading:false
        })
        
    }else{
        this.setState({
          isLoading:false
        })
      }
   
  }
  render() {
    const tabsArray = this.state.CategoryMenus;
    //console.log(this.props.history.location.pathname);
  

    return (
      <div>
        <div id="accordion">
   
          {
            tabsArray.map((item, index) => {
              return (
                <div className="menu-list" key={index}>
                { item.children.length !== 0?
                  <div className="title flex jc-btwn" onClick={e => this.handleTabClick(item.id)}>
                    <span>{item.icon} {item.title}</span>
                     <i className={item.active ? "i-angle-up" : "i-angle-down"}></i>
                  </div>:
                  <a href={'/category/'+item.slug}>
                  <div className="title flex jc-btwn" onClick={e => this.handleTabClick(item.id)}>
                    <span> {item.icon} {item.title}</span>
                  </div>
                  </a>
                }
                 { item.children.length !== 0 ?
                  <div className={item.active ? "dropdown-menu-list show" : "dropdown-menu-list hide"}>
                    <ul>
                    {
                      item.children.map((tabSub, index) => {  
                            return (<li key={index}><Link to={'/category/'+item.slug+'/' + tabSub.slug}>{tabSub.title}</Link></li>)
                     }) 
                    }
                    </ul>
                  </div> : null
                 }
                </div>
              )
            })


          }
        </div>
      </div>
    )
  }
}
export default withRouter(CategoryMenu)