import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { GetCategories } from '../../helper'
class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            filtredItems: [],
            total_categories:[],
            parent_cat_title:null,
            parent_cat_slug:null,

        }
    }
    // handleOnSearch = (string, results) => {
    //     // onSearch will have as the first callback parameter
    //     // the string searched and for the second the results.
    //     console.log(string, results)
    //   }



    handleOnSelect = async (item) => {
        const {total_categories} = this.state;
        console.log(item.name)
        console.log(item.slug)
        console.log(item.parent_id)
        let par_id = parseInt(item.parent_id);
        if(par_id !== 0){
            const patent_cat = total_categories.find(val => val.id === par_id)
            this.setState({
                parent_cat_title:patent_cat.title,
                parent_cat_slug:patent_cat.slug,
            }, () => {
                this.props.history.push('/category/'+this.state.parent_cat_slug+'/' + item.slug);
            });
          
        }else{
            this.props.history.push('/category/'+ item.slug);
        }
        
 
    }

    myFunction = (item, index) => {
        this.setState({
            filtredItems: this.state.filtredItems.concat({ 'id': item.id, 'name': item.title, 'slug': item.slug ,'parent_id': item.parent_id}),
            isLoading: false
        })
    }

    async componentDidMount() {
        let resp = await GetCategories.getSearchCategories();
        if (resp) {
            const items = resp.categories;
            this.setState({
                total_categories: resp.categories,
                isLoading:false
            })

            items.forEach(this.myFunction)

        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    render() {
        const { items, isLoading, filtredItems } = this.state;
       
        return (
            <div className="serch">
                <div className="searchbar">
                    <ReactSearchAutocomplete
                        items={!isLoading && filtredItems}
                        onSearch={this.handleOnSearch}
                        onHover={this.handleOnHover}
                        onSelect={this.handleOnSelect}
                        onFocus={this.handleOnFocus}
                        placeholder="Write Your Bussiness"
                        autoFocus
                    />

                </div>
            </div>
        )
    }
}
export default withRouter(SearchBar)