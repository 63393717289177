import React, {useState} from 'react'
import Breadcrumb from '../common/Breadcrumb'
import CategoryMenu from '../pages/categories/CategoryMenu'
import { useLocation } from 'react-router-dom';
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Categories",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]
const CategoryProductLayout = ({ children, }) => {
    const [showcategory, setShowCategory] = useState(false);
    const showCategory = ()=>{
        setShowCategory(!showcategory);
    }
    const location = useLocation();
    const pathname = location.pathname;
    localStorage.setItem('currentLocation', pathname);
 
    return (
        <>
            <div className="content-wrapper">
                <Breadcrumb BreadcrumbData={BreadcrumbData} />
                <div className="container mt-20">
                <button className="category-menu-bar" onClick={showCategory}>
                    <h3><i className="i-category"></i> Categories</h3>
                </button>
                    <div className="row">
                        <div className={`col-3 col-sm-12`} >
                            <div className={`menu-listing-box ${showcategory ? 'shown-category' : ''}`}>
                                <div className="menu-listing-header">
                                    <h3>Category</h3>
                                </div>
                                <CategoryMenu showCategory={showCategory} location={pathname}/>
                            </div>
                        </div>
                        <div className="col-9 col-sm-12">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryProductLayout
