import React, { Component } from 'react'
import parse from 'html-react-parser';
export default class FaqItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs1: []  
    }
    this.handleTabClick = this.handleTabClick.bind(this);
    this.updateTabs = this.updateTabs.bind(this);
  }

  updateTabs(id) {
    let tabs = this.props.tabs;
    let newtabs = tabs.map((tab, index) => {
      if (tab.id === id) {
        if (tab.active === true) {
          tab.active = 0;
        } else {
          tab.active = 1;
        }
      } else {
        tab.active = 0;
      }
      return tab;
    });
    return newtabs;
  }

  handleTabClick(id) {
    this.setState({ tabs: this.updateTabs(id) });
  }





  render() {
    const tabsArray = this.props.tabs;
    return (
      <div>
        <div id="accordion">
          {
            tabsArray.map((tab, index) => {
              return (
                <div className="tab" key={index}>
                  <div className={parseInt(tab.active) === 1 ? "title flex jc-btwn active" : "title flex jc-btwn"} onClick={e => this.handleTabClick(tab.id)}>
                    <span>{tab.questions}</span>
                    <i className={parseInt(tab.active) === 1 ? "i-minus" : "i-plus"}> </i>
                  </div>
                  <div className={parseInt(tab.active) === 1 ? "content show" : "content hide"}>
                    {parse(tab.answers)}
                  </div>
                </div>
              )
            })


          }
        </div>
      </div>
    )
  }
}
