const lanyard_printing_method_options = [
    {
        text: "Full Color Dye-sublimation / Heat Transfer",
        image: "lpm1.jpg",
        value: "Full Color Dye-sublimation / Heat Transfer"
    },
    {
        text: "Silkscreen Printing",
        image: "lpm2.jpg",
        value: "Silkscreen Printing"
    },
    {
        text: "Embroidery",
        image: "lpm3.jpg",
        value: "Embroidery"
    }

]
export default lanyard_printing_method_options;