import React, { Component } from 'react'
import {Link} from "react-router-dom";
import fl1 from '../../assets/img/fl-1.png';
import fl2 from '../../assets/img/fl-2.png';
import fl3 from '../../assets/img/fl-3.png';
// import { GetMenus } from '../../helper'
export default class Footer extends Component {
//   constructor(){
//     super();
//     this.state={
//       catelogMenu:[],
//       isLoading:true
//     }
//   }
//   async componentDidMount() {
//     let resp = await GetMenus.getCatelogueMenus();

//     if (resp) {
//         this.setState({
//             catelogMenu: resp.menus,
//             isLoading: false
//         })
//     } else {
//         this.setState({
//             isLoading: false
//         })
//     }

// }
  render() {
    // const {catelogMenu, isLoading} = this.state;
    // console.log(catelogMenu);
    return (
      <footer>
      <div className="footer-top text-center">
          <p>For Instant order call now </p>
          <Link to="#">+65 9119 5104</Link>
      </div>
      <div className="footer-main">
        <div className="footer-middle">
        <div className="container">
           <div className="row">
               <div className="col-4 col-sm-12">
                 <div className="footer-box">
                   <div className="footer-title">
                      <h3>About Us</h3>
                   </div>
                   <div className="footer-box-content">
                   <p>Started humble in 2012, Luminous Printing is a T-shirt printing company in Singapore. We do not focus our business</p>
                   <p>We have a huge variety of T-Shirts or Corporate tops that are of different materials. Ranging from round-neck to polo (collar tee), windbreakers to</p>

                   <ul className="logo-list">
                     <li><img src={fl1} alt="fl-1"/></li>
                     <li><img src={fl2} alt="fl-2"/></li>
                     <li><img src={fl3} alt="fl-3"/></li>
                   </ul>
                   </div>

                 </div>
               </div>
               <div className="col-2 col-sm-6">
               <div className="footer-box">
                   <div className="footer-title">
                      <h3>Catelogue</h3>
                   </div>
                   <div className="footer-box-content">
                     <ul className="footer-menu">
                        {/* {
                          !isLoading ?  
                          <>
                            {
                              catelogMenu.map((item, index) => {
                                return <li><Link key={index} to={'/category/' + item.category.slug}>{ item.category.title }</Link></li>
                             })
                            }
                          </>
                          :null

                        } */}
                        <li><Link to="/">Custom Made Order</Link></li>
                        <li><Link to="/category/t-shirts">T-shirts</Link></li>
                        <li><Link to="/category/polo-t-shirts">Polo T-shirts</Link></li>
                        <li><Link to="/category/caps">Caps</Link></li>
                        <li><Link to="/category/towel">Towel</Link></li>
                        <li><Link to="/category/outerwear/hoodies">Hoodies</Link></li>
                        <li><Link to="/">Lanyards</Link></li>
                        <li><Link to="/category/stationeries">Pens</Link></li>
                        <li><Link to="/category/bag">Bags</Link></li>
                        <li><Link to="/category/drinkware">Drinkware</Link></li>
                     </ul>
                   </div>
                 </div>
               </div>
               <div className="col-2 col-sm-6">
               <div className="footer-box">
                   <div className="footer-title">
                      <h3>Explore</h3>
                   </div>
                   <div className="footer-box-content ">
                     <ul className="footer-menu">

                     <li><Link to="/about">About us </Link></li>
                     <li><Link to="/">Blog </Link></li>
                     <li><a href="/category/apparels">Apparels Catalogue </a></li>
                     <li><a href="/category/corporate-gifts">Corporate Gifts Catalogue </a></li>
                     <li><Link to="/printing-methods">Printing Services </Link></li>
                     <li><Link to="/recent-projects">Recent Projects </Link></li>
                     <li><Link to="/">Google Reviews </Link></li>
                     <li><Link to="/how-to-order">How to Order </Link></li>
                     <li><Link to="/faqs">FAQS </Link></li>
                     <li><Link to="/">Contact us </Link></li>

                     {/* <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                     <li><Link to="/term-conditions">Terms & Conditions</Link></li> */}
                     </ul>
                   </div>
                 </div>
               </div>
               <div className="col-4 col-sm-12">
               <div className="footer-box address-bar">
                   <div className="footer-title">
                      <h3>Contact Us</h3>
                   </div>
                   <div className="footer-box-content address-box">
                   <p>BLK 708 Hougang Ave 2, #09-57 Singapore 530708</p>
                   <p className="before-call"> +65 9119 5104</p>
                   <p className="before-email"> sales.luminousprinting@gmail.com</p>
                   <h3>OPENING HOURS</h3>
                   <p>Luminous Printing located at Hougang Ave 2 is a SOHO.</p>
                   <p>Visit Hours: Strictly for appointment only, please schedule with our salesperson before drop by. Our production office is located at Potong Pasir.</p>
                   <p>Monday - Friday: 09:00am to 05:00pm</p>
                   <p>Close on Saturday, Sunday and PH</p>
                   <p>(Whatsapp is on 7/24)</p>
                   
                   </div>
                 </div>
               </div>
           </div>
           </div>
        </div>
        <div className="container">
        <div className="footer-bottom">
          <p>© 2021 Luminous Printing Apparel & Gifts Singapore. All Rights Reserved.</p>
        </div>
      </div>
      </div>
  </footer>
    )
  }
}
