import React from 'react'

const PrintingMethodMenuItem = ({item}) => {
   
    return (
       <>
           <a href={`/printing-method/${item.slug}`}>{ item.title }</a>
       </>

    
    )
}

export default PrintingMethodMenuItem
