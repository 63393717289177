import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Breadcrumb from '../../common/Breadcrumb'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Contact us",
        page_path: "/contact-us",
        icon: "ews ewse_gaphicsdes",
    }
]
const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: -3.745,
    lng: -38.523
};

function Contact() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "YOUR_API_KEY"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    document.title = 'contact';
    document.getElementsByTagName("META")[3].content = 'contact';
    document.getElementsByTagName("META")[4].content = 'contact';
    return isLoaded ? (
        <div className="content-wrapper">
            <Breadcrumb BreadcrumbData={BreadcrumbData} />


            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                { /* Child components, such as markers, info windows, etc. */}
                <></>
            </GoogleMap>
            <div className="container">
                <div className="page-desc">
                    <div className="row">
                        <div className="col-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="contact-box">
                        <h1>👋 Get in touch!</h1>
                            <ul className="get-in-touch">
                                <li><a href="!#"><i className="i-call"> </i> +65 9119 5104</a> </li>
                                <li><i className="i-whatsapp"> </i> <a href="https://api.whatsapp.com/send/?phone=6591195104&text=Hi&app_absent=0" target="_blank" rel="noreferrer">WhatsApp Us</a></li>
                                <li><a href="!#"> <i className="i-email"> </i> Email Us: sales.luminousprinting@gmail.com</a></li>
                                <li><strong>Address: #09-57 Hougang Ave 2, Block 708, Singapore 530708</strong> </li>
                            </ul>
                        </div>
                           
                        </div>
                        <div className="col-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="contact-box">
                            <h1>🕘 Opening Hours</h1>
                            <ul className="get-in-touch">
                                <li>Mon – Fri: 9am – 6pm</li>
                                <li>Sat, Sun & Public Holidays: Closed</li>
                            </ul>
                          </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    ) : <></>
}

export default React.memo(Contact)