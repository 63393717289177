import React, { Component } from 'react'
import { GetMenus } from '../../../helper'
import Breadcrumb from '../../common/Breadcrumb'
import CategoryListingItem from './CategoryListingItem';
import parse from 'html-react-parser';
export default class CategoryListing extends Component {
    constructor() {
        super();
        this.state = {
            categoryList: [],
            isLoading: true
        }
    }
    async componentDidMount() {
        let resp = await GetMenus.getAllMenus();
        const pathname = window.location.pathname
        var lastPart = pathname.split("/").pop();
      
        if (resp) {
            const catList = resp.menus.find(item => item.slug === lastPart);
            this.setState({
                categoryList: catList,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    render() {
        const { categoryList, isLoading } = this.state;
     console.log(categoryList.menu_list);


        return (
            <>
 {
                    !isLoading ?<>
            <div className="content-wrapper bg-light-gray">
                <Breadcrumb BreadcrumbData={[{
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: 'Category' ,
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 3,
        page_title: categoryList.title ,
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
    
    ]} />


                <div className="container ">

                    <div className="page-desc">
                   
                        <div className="section-title text-center">
                            <h2>{ categoryList.title }</h2>
                            <div className="border-bottom-line">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="page-desc">
                        { parse(categoryList.description) }

                    <div className="row">
                    {
                        categoryList.menu_list.map((item, index) => {
                        return <CategoryListingItem key={index} item={item} />
                      }) 
                    }
                    </div>
                    </div>

                      
                    </div>
                </div>
            </div>
            </> : <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>
                     } 
        </>
        )
    }
}
