const lanyard_color_options = [
    {
        text: "Black",
        image: "black.jpg",
        value: "Black"
    },
    {
        text: "Customize",
        image: "customize.jpg",
        value: "Customize"
    },
    {
        text: "Full Colorus",
        image: "full-color.jpg",
        value: "Full Colorus"
    },
    {
        text: "Green 348C",
        image: "greem348c.jpg",
        value: "Green 348C"
    },
    {
        text: "Navy 2965C",
        image: "naviblue.jpg",
        value: "Navy 2965C"
    },
    {
        text: "Orange 021C",
        image: "orange.jpg",
        value: "Orange 021C"
    },
    {
        text: "Purple 2593C",
        image: "purpal.jpg",
        value: "Purple 2593C"
    },
    {
        text: "Red 186C",
        image: "red.jpg",
        value: "Red 186C"
    },
    {
        text: "Royal Blue Reflex C",
        image: "royalblue.jpg",
        value: "Royal Blue Reflex C"
    },
    {
        text: "Sea Blue 801C",
        image: "seeblue.jpg",
        value: "Sea Blue 801C"
    },
    {
        text: "White",
        image: "white.jpg",
        value: "White"
    },
    {
        text: "Yellow 012C",
        image: "yellow012c.jpg",
        value: "Yellow 012C"
    }
]

export default lanyard_color_options;