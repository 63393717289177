import React, { Component } from 'react'
import CategoryItems from './CategoryItems'
import { GetProducts } from '../../../helper'
export default class Category extends Component {
    constructor() {
        super();
        this.state = {
            products: [],
            isLoading:true,
            limit: 8
        }
        this.onLoadMore = this.onLoadMore.bind(this);
    }
    onLoadMore() {
        this.setState({
            limit: this.state.limit + 8
        });
    }
    async componentDidMount() {
        let resp = await GetProducts.getAllProducts();
        if(resp){
            this.setState({
                products: resp.products,
                isLoading:false
            })
        }else{
            this.setState({
             isLoading:false
         }) 
        }


        // axios.get(`http://127.0.0.1:8000/api/products`)
        //     .then(res => {
        //         const resData = res.data.products;
        //         this.setState({
        //             products: resData,
        //             isLoading:false
        //         })
        //         console.log(resData);
        //     }).catch((error) => {
        //         console.log(error)
        //         this.setState({
        //             isLoading:false
        //         })
        //     });
    }
    render() {
        const { products, isLoading } = this.state;
       const showProduct = products.slice(0,this.state.limit);
        console.log(showProduct);
        return (
            <>
           
                <div className="category-listing">
                    <div className="category-title">
                        <h3>Products</h3>
                    </div>
                    <div className="category-listing-items">
                    {
                        isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>

                        :<><div className="row">
                            {
                            showProduct.map((item, index) => {
                                    return <CategoryItems key={index} item={item} />
                                }) 
                            }


                        </div>
                        <div className="view-more-transparent">
                        {showProduct.length === products.length ? null : <button onClick={this.onLoadMore}>View More Polo T‑shirts</button>}
                        </div>
                        </>
                    }
                        
                       
                    </div>
                </div>
               
            </>
        )
    }
}
