import React, { Component } from 'react'
import { connect } from 'react-redux';
import { removeFromCart, incrementToCart, decreaseToCart } from "../../services/actions/cartActions";
import { NavLink, Link } from "react-router-dom";
// import { PrintingMethods, Apparel, CorporateGifts } from './submenu/Submenu';
import MenuItems from './menus/MenuItems'
import PrintingMethodMenuItem from './menus/PrintingMethodMenuItem'
import { GetMenus } from '../../helper'
import MainLogo from './logo.png';
import SearchBar from '../common/SearchBar'


class Header extends Component {
    constructor() {
        super();
        this.state = {
            menuList: [],
            printingMethodListing: [],
            isLoading: false,
            isLoading2: false
        }
    }
    getData = async () => {
            let resp = await GetMenus.getAllMenus();
            if (resp) {
                localStorage.setItem('manuList', JSON.stringify(resp.menus));
                this.setState({
                    menuList: resp.menus,
                    
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                })
            
        }
      
        let resp2 = await GetMenus.getAllPrintingMethodMenus();
        if (resp2) {
           // console.log(resp2.printing_method_menu);
            this.setState({
                printingMethodListing: resp2.printing_method_menu,
                isLoading2: false
            })
        } else {
            this.setState({
                isLoading2: false
            })
        }
    }
    componentDidMount() {
        const menudata = JSON.parse(localStorage.getItem('manuList'));
        if(menudata && menudata.length !== 0){
            this.setState({
                menuList: menudata,
                isLoading: false
            });
            this.getData();
        }else{
            this.getData();
        }
       
      }
    

    render() {
        const { cartItems } = this.props;
        const { menuList, isLoading, printingMethodListing, isLoading2 } = this.state;
        let isAuthenticated = localStorage.getItem('isAuthenticated');

     
      
      
        const printingMenu1 = printingMethodListing.filter(item => item.printing_method_type === "1");
        const printingMenu2 = printingMethodListing.filter(item => item.printing_method_type === "0");
    

        return (
            <header className="header">
                <div className="container">
                    <div className="brand"><button className="menubutton"><span></span><span></span><span></span></button> <Link to="/"><img src={MainLogo} alt="Luminous Printing" /></Link>  </div>
                    <SearchBar />
                    <div className="navebar">
                        <ul className="nav">
                            {
                                isLoading ? null :
                                    menuList.map((item, index) => {
                                        return <li className="submenu"><a href={`/category/${item.slug}`} className="main-menu-link">{item.title}</a><i className="i-angle-down"></i>
                                            {/* submenu start */}
                                            <div className="megamenu">
                                                <div className="backdrop">
                                                    <div className="submenulist container">
                                                        <div className="row">
                                                            {
                                                                item.menu_list.map((subnav, i) => {

                                                                    return <MenuItems key={i} subnav={subnav} />
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* submenu end */}
                                        </li>
                                    })
                            }

                            <li className="submenu">Services<i className="i-angle-down"></i>
                                {/* submenu start */}
                                {
                                    isLoading2 ? null :
                                        <div className="megamenu">
                                            <div className="backdrop">
                                                <div className="submenulist container">
                                                 <div>
                                                 <h3><NavLink to="/">👕 T-shirt Printing Methods</NavLink></h3>
                                                    {
                                                        printingMenu1.map((item, index) => {
                                                            return <PrintingMethodMenuItem key={index} item={item} />
                                                        })
                                                    }
                                                    {/* <a href={`/`}>Custom Made Apparel (Made-to-order)</a> */}
                                                    
                                                    </div>
                                                 
                                                    <div>
                                                    <h3><NavLink to="/">👕 Corporate Gifts Printing Service Methods</NavLink></h3>
                                                    {
                                                        printingMenu2.map((item, index) => {
                                                            return <PrintingMethodMenuItem key={index} item={item} />
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                }

                                {/* submenu end */}
                            </li>
                           
                           
                            <li className="submenu"><NavLink to="/lanyard-printing" activeClassName="activelink">Lanyard<i className="i-angle-down"></i></NavLink>
                            <div class="dropdown-list">
                            <div class="backdrop-menu">
                            <div>
                            <NavLink activeClassName="activelink" to="/lanyard-printing-enquiry">How to order ( Lanyard )</NavLink>
                            </div>
                            </div>
                            </div>
                            </li>


                            <li><NavLink to="/recent-projects" activeClassName="activelink">Recent Projects</NavLink></li>
                            {/* <li><NavLink to="/" activeClassName="activelink">Reviews</NavLink></li> */}
                            <li className="submenu">Helps<i className="i-angle-down"></i>
                            <div class="dropdown-list">
                            <div class="backdrop-menu">
                            <div>
                            <NavLink activeClassName="activelink" to="/faqs">Faqs</NavLink>
                            <NavLink activeClassName="activelink" to="/blogs">Blogs </NavLink>
                            <NavLink activeClassName="activelink" to="/graphic-design-service">Graphic Design</NavLink>
                            <NavLink activeClassName="activelink" to="/how-to-order">How to order </NavLink>
                            <NavLink activeClassName="activelink" to="/delivery-information">Delivery Information  </NavLink>
                            <NavLink activeClassName="activelink" to="/size-chart-information">How To Use Size Chart  </NavLink>
                            <NavLink activeClassName="activelink" to="/pantone-color-chart">Pantone Color Chart</NavLink>
                            
                            
                            </div>
                            </div>
                            </div>
                            </li>
                            <li><a href="/contact-us" activeClassName="activelink">Contacts</a></li>
                        </ul>
                        <li className="user-link">
                            {
                                isAuthenticated && isAuthenticated === 'true' ? <NavLink to="/user/dashboard"><i className="i-user"></i> </NavLink> :
                                    <NavLink to="/login"><i className="i-user"></i> </NavLink>
                            }
                        </li>
                        <li className="cart">
                            <NavLink to="/enquiry-cart"><i className="i-cart"></i> <sup><span>{cartItems.length}</span></sup> </NavLink></li>
                    </div>


                </div>
            </header>
        )
    }
}
export default connect(
    (state) => ({
        cartItems: state.cart.cartItems,
    }),
    { incrementToCart, decreaseToCart, removeFromCart }
)(Header);