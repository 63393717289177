import React, { Component } from 'react'


export default class ProducTypeAttribute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImageIndex: 0,
            product_price: 0,
            product_type:'',
        };

        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
        this.bulletSlide = this.bulletSlide.bind(this);
       // this.getValue = this.getValue.bind(this);
    }

    previousSlide() {
        const lastIndex = this.props.attributeData.length - 1;
        const { currentImageIndex } = this.state;
        const shouldResetIndex = currentImageIndex === 0;
        const index = shouldResetIndex ? lastIndex : currentImageIndex - 1;

        this.setState({
            currentImageIndex: index
        });

    }

    nextSlide() {
        const lastIndex = this.props.attributeData.length - 1;
        const { currentImageIndex } = this.state;
        const shouldResetIndex = currentImageIndex === lastIndex;
        const index = shouldResetIndex ? 0 : currentImageIndex + 1;

        this.setState({
            currentImageIndex: index
        });
    }
    bulletSlide(index, label) {
        this.setState({
            currentImageIndex: index,
            product_type:label
        });
        
    }
    handleTypeChang = (e) =>{
        const num = Number(e.target.value);
        // console.log(typeof(num));
        this.setState({
            product_price: num
        }, () => {
          
            this.props.onTypeChange(this.state);
        });

     
        // const data = {
        //     prod_type:this.state.product_type,
        //     prod_price:e.target.value
        // }
        // this.props.onTypeChange(data);
    }

    render() {
     
        
        return (
            <>
                <div className="custom-options">
                <h5>Custom Options</h5>
                    <div className="row">
                        <div className="col-4">
                         <div className="sample-image">
                            <img src={this.props.attributeData[this.state.currentImageIndex].sample_img} alt="" />
                            </div>
                        </div>
                        <div className="col-8">
                       
                            {this.props.attributeData.map((item, i) => (
                                <label className={`attribute-label ${this.state.currentImageIndex === i ? 'active' : ''}`} key={i} onClick={e => this.bulletSlide(i, item.label)}>{item.label}
                                 <input type="radio" checked={`${this.state.currentImageIndex === i ? 'checked' : ''}`} name="radio" value={item.price} onChange={this.handleTypeChang}/>
                                 <span className="checkmark"></span>
                               </label>
                            ))}
                        </div>
                    </div>
                    <h5>Price includes</h5>
                    
<p>💪 <strong>Leadtime :</strong> { this.props.leadtime }</p>
<p>🚚 <strong>Delivery :</strong> { this.props.delivery }</p>
                </div>

            </>
        )
    }
}
