import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAllCategories = async () => {
    try {
        let result = await api.get(Apis.GetAllCategoryList);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getHomeCategories = async () => {
    try {
        let result = await api.get(Apis.GetHomeCategoryList);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const getSearchCategories = async () => {
    try {
        let result = await api.get(Apis.GetSearchCategories);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const exportedObject = {
    getAllCategories,
    getHomeCategories,
    getSearchCategories
};
export default exportedObject;