import React from 'react'
import { Link } from 'react-router-dom'
import dummyImg from '../../../assets/img/ImageNotFound.svg'
const CategoryItems = ({item, category, categorySlug}) => {
  let category_slug = null;
  if(categorySlug === undefined){
    category_slug = category.category_slug;
  }else{
    category_slug=categorySlug;
  }
  let path = null;
if(category.parent_category_slug === undefined){
  path = "/product/"+category_slug+"/"+item.slug;
}else{
  path = "/product/"+category.parent_category_slug+"/"+category_slug+"/"+item.slug;
}

    return (
        <div className="col-3 col-sm-6">
         <Link to={path}>
        <div className="category-item-box">
            <div className="category-thumb">
            <img src={item.product_images[0] ? item.product_images[0].image_name : dummyImg} alt="Luminous Printing"/>
            </div>
            <div className="category-excerpt">
            <div className="category-item-title">
              <h4>  {item.title}</h4>
              {
                parseInt(item.other_product) !== 1 ? <>
                <span className="category-item-price">
              From ${item.base_price}/pc 
              </span>
              <span className="category-item-atributes-size">
               Size {item.size_range}
              </span>
              <span className="category-item-atributes">
              Comes in {item.colors} Colors
              </span>
                </> : null
              }
            
            </div>
            </div>
            {/* <div className="view-more">
                <Link to={`/product/${item.slug}`}>View More</Link>
            </div> */}
        </div>
        </Link>
        
      </div>
    )
}

export default CategoryItems
