
const lanyard_pvc_card_options = [
    {
        text: "None",
        image: "pc1.jpg",
        value: "None"
    },
    {
        text: "Individual Details",
        image: "pc2.jpg",
        value: "Individual Details"
    },
    {
        text: "Same Details",
        image: "pc3.jpg",
        value: "Same Details"
    }
]
export default lanyard_pvc_card_options;