import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { GetUserLogin, Alerts, GetUserOrder } from '../../../helper';

const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Submit Enquiry",
        page_path: "/submit-enquiry",
        icon: "ews ewse_gaphicsdes",
    }
]

export class SubmitEnquiry extends Component {
    constructor() {
        super();
        this.state = {
            isEditProfile: false,
            userDetails: null,
            user_id: null,
            fname: null,
            lname: null,
            email: null,
            mobile_number: null,
            organization_name:null,
            requirement_info:null,
            isLoading:false,
            cartItems:[],
            file1:null,
            file2:null,
            file3:null,
            serverError:null,
            isEnquirySubmitted:false,
            isEnquiryLoading:false
            
        }
        this.toggleEditProfile = this.toggleEditProfile.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

   componentDidMount(){
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    let user = null;
    let cartItems = null;
    if (isAuthenticated && isAuthenticated === 'true') {
        user = JSON.parse(localStorage.getItem('userData'))
        cartItems = JSON.parse(localStorage.getItem('cartItems'))
        this.setState({
            user_id:user.id,
            fname:user.fname,
            lname:user.lname,
            email:user.email,
            mobile_number:user.mobile_number,
            cartItems:cartItems
        })
    }
   }


    toggleEditProfile() {
        this.setState({
            isEditProfile: !this.state.isEditProfile,
        })
    }


    async onEdit() {
        this.setState({
            isEditProfile: !this.state.isEditProfile,
            isUserUpdatting:false,
            validationErrors:[]
        })
       
    }
    handleOrganizationNameChange = e => {
        this.setState({
            organization_name: e.target.value,
        })
    }
    handleRequirementInfoChange = e => {
        this.setState({
            requirement_info: e.target.value,
        })
    }

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({[name]: value });
    };

    handleUpdateSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isUserUpdatting: !this.state.isUserUpdatting,
        })
        let { user_id, fname, lname, email, mobile_number } = this.state;
        let data = { user_id: user_id,fname: fname, lname: lname, email: email, mobile_number: mobile_number  }
      
        let resp = await GetUserLogin.getCustomerUpdate(data);
        if(resp){
           if(resp.status === true){
             localStorage.setItem('userData', JSON.stringify(resp.user));   
             Alerts.alertMessage("User updated successfully.", "Nice !", "success");
             this.setState({
                isEditProfile: !this.state.isEditProfile,
                isUserUpdatting: !this.state.isUserUpdatting,
                validationErrors:[]
            })
           }
           if(resp.status === false){
               this.setState({ 
                   validationErrors : resp.errors,
                   isUserUpdatting: !this.state.isUserUpdatting, 
                });
           }
        }
    }
    handleAtworkfileChange1= e => {
        this.setState({
           file1:e.target.files[0],
        }) 
      }
      handleAtworkfileChange2= e => {
        this.setState({
           file2:e.target.files[0],
        }) 
      }
      handleAtworkfileChange3= e => {
        this.setState({
           file3:e.target.files[0],
        }) 
      }
    enquirySubmit = async (event) => {
        event.preventDefault();
        this.setState({
            isEnquiryLoading:true,
        });
        const {user_id, fname, lname, email, mobile_number, organization_name, requirement_info, cartItems,file1,file2,file3} = this.state;
        var json_arr = JSON.stringify(cartItems);
        const formData = new FormData();
        formData.append('file1',file1);
        formData.append('file2',file2);
        formData.append('file3',file3);
        formData.append('user_id',user_id);
        formData.append('fname',fname);
        formData.append('lname',lname);
        formData.append('email',email);
        formData.append('mobile_number',mobile_number);
        formData.append('organization_name',organization_name);
        formData.append('requirement_info',requirement_info);
        formData.append('cartItems',json_arr);
        let resp = await GetUserOrder.createUserOrder(formData);
        if(resp){
           if(resp.status === false){
               this.setState({
                serverError:resp.error,
                isEnquiryLoading:false
               })
           }
         if(resp.status === true){
            localStorage.removeItem("cartItems")  
            this.setState({
                isEnquiryLoading:false
               })
            window.location.reload(this.props.history.push({
                pathname:"/enquiry-responce",
                state:{
                    key:"form-submitted"
                 }
               }))
         }

        }
    }

    render() {
        const { cartItems } = this.props;
        const { isEditProfile, isUserUpdatting, fname, lname, email, mobile_number,validationErrors, organization_name, requirement_info, serverError } = this.state;
        const cartItem = JSON.parse(localStorage.getItem('cartItems'))
        if(cartItem){
            if(cartItem.length === 0){
                return <Redirect to="/"/>
            }
        }else{
            return <Redirect to="/"/>
        }
        return (
            <>
                <div className="content-wrapper">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="container mt-30">
                        <div className="enquiry-submit-page">

                            <div className="row">
                                <div className="col-6 col-sm-12">
                                    <div className="section-form-title">
                                        <h2> <i class="i-edit"></i> Your Details </h2>
                                    </div>

                                   { serverError !== null ? <div className="alert alert-danger">{ serverError }</div> : null}
                                    <div className="enquiry-form-wrapper">
                                        <button className="profile-edit-btn" onClick={this.onEdit}>{!isEditProfile ? "Edit Profile" : "Close"}</button>
                                        {
                                            isEditProfile ?
                                                <>
                                                    <form onSubmit={this.handleUpdateSubmit}>
                                                        <div className="row">
                                                            <div className="form-group enquiry-from-control col-6">
                                                                <label htmlFor=""> First Name *</label>
                                                                <input type="text" className="form-control" name="fname" value={fname} onChange={this.handleChange}/>
                                                                <span className="error-help">{validationErrors['fname']}</span>
                                                            </div>
                                                            <div className="form-group enquiry-from-control col-6">
                                                                <label htmlFor=""> Last Name *</label>
                                                                <input type="text" className="form-control" name="lname" value={lname} onChange={this.handleChange}/>
                                                                <span className="error-help">{validationErrors['lname']}</span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group enquiry-from-control">
                                                            <label htmlFor=""> Your Contact No. *</label>
                                                            <input type="text" className="form-control" name="mobile_number" value={mobile_number} onChange={this.handleChange}/>
                                                            <span className="error-help">{validationErrors['mobile_number']}</span>
                                                        </div>
                                                        <div className="form-group enquiry-from-control">
                                                            <label htmlFor=""> Your Email Address *</label>
                                                            <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange}/>
                                                            <span className="error-help">{validationErrors['email']}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit" className="profile-update-btn">{ isUserUpdatting? "Please Wait...": "Update Now" } </button>
                                                        </div>

                                                    </form>
                                                </> : null
                                        }

                                        <div className="form-group enquiry-from-control">
                                            <label htmlFor=""> Your Organization / Company / School *</label>
                                            <input type="text" className="form-control" value={organization_name} onChange={this.handleOrganizationNameChange}/>
                                        </div>
                                        <div className="form-group enquiry-from-control">
                                            <label htmlFor="">Additional Information for us to understand your requirements better (optional)</label>
                                            <textarea className="form-control" onChange={this.handleRequirementInfoChange}>{requirement_info}</textarea>
                                        </div>
                                    </div>
                                    <div className="enquiry-form-wrapper">
                                        <div className="enquiry-form-bottom">
                                            <h2>🎨 Upload Your Artwork files here!</h2>
                                            <h4>💡 Pro Tips- For more than 3 files , compress to zip😸</h4>
                                            <div className="atwork-files">
                                                <div className="form-group">
                                                    <div className="form-group enquiry-from-control">
                                                        <input type="file" className="form-control" onChange={this.handleAtworkfileChange1} name="file1" accept=".jpg,.pdf,.ai,.png,.zip,.7zip"/>
                                                    </div>
                                                    <div className="form-group enquiry-from-control">
                                                    <input type="file" className="form-control" onChange={this.handleAtworkfileChange2} name="file2" accept=".jpg,.pdf,.ai,.png,.zip,.7zip"/>
                                                    </div>
                                                    <div className="form-group enquiry-from-control">
                                                    <input type="file" className="form-control" onChange={this.handleAtworkfileChange3} name="file3" accept=".jpg,.pdf,.ai,.png,.zip,.7zip"/>
                                                    </div>
                                                    <small>Note - Only .pdf,.ai,.png,.jpg,.zip files are acceceptable . </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-12">
                                    <div className="section-form-title">
                                        <h2> <i class="i-cart"></i> Your Enquiry Cart</h2>
                                    </div>

                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th><h3>Product</h3></th>
                                                <th><h3>Price/Pc</h3></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cartItems.map((item, index) => {
                                                    return (<tr>
                                                        <td>
                                                            <div className="product-cart-image ">
                                                                <img src={item.product_image} alt="Luminous Printing" />

                                                                <h4><Link to="#">{item.product_title}</Link></h4>
                                                                <p>Quantity Required: {item.quantity}</p>
                                                                <p>🎨 Custom Option: {item.print_type} </p>
                                                                <p>💪 Leadtime: {item.leadtime}</p>
                                                                <p>🚚 Delivery: {item.delivery}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="proce-in-table">
                                                                <h4>${item.final_price}</h4>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                    <div className="submit-enquiry-btn">
                                        <button className="cart-submit-continue" onClick={this.enquirySubmit}> { this.state.isEnquiryLoading ? "Please wait.." : "Submit Enquiry"} </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default connect(
    (state) => ({
        cartItems: state.cart.cartItems,
    }),
)(SubmitEnquiry);

