import React, { Component } from 'react'
import { GetPages } from '../../../helper'
import FaqItems from './faqs/FaqItems'
export default class Faqs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: [],
      isLoading: true

    }
  }

  async componentDidMount() {
    let resp = await GetPages.getHomeFaq();
    if (resp) {
      this.setState({
        tabs: resp.faqs,
        isLoading: false
      })
    } else {
      this.setState({
        isLoading: false
      })
    }
  }
  render() {
    const { isLoading, tabs } = this.state;
    return (
      <>
        <div className="faq-section section-padding">
          <div className="container">
            <div className="section-title text-center">
              <h2>FAQs</h2>
              <div className="border-bottom-line">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="faq-box">
              {

                isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader" /></div>

                  :  <FaqItems tabs={tabs} />
                
                     

              }


            </div>
          </div>
        </div>
      </>
    )
  }
}
