import api from '../../ApiConfig';
import { Apis } from '../../config';

// import { NotificationManager } from 'react-notifications';

const createUserOrder = async (data) => {
    try {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        let userToken = null;
        if (isAuthenticated && isAuthenticated === 'true') {
            userToken = JSON.parse(localStorage.getItem('userToken'))
        }
        let result = await api.post(Apis.CreateUserOrder, data, {
            headers: {
                'Authorization': `Bearer ${userToken}`  
            }
           
        });
        // if (result.data.error) {
        //     // NotificationManager.error(result.data.error);
        //     console.log(result.data.error);
        //     return null;
        // }
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getUserOrder = async (user_id) => {
    try {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        let userToken = null;
        if (isAuthenticated && isAuthenticated === 'true') {
            userToken = JSON.parse(localStorage.getItem('userToken'))
        }
        let result = await api.get(Apis.GetUserOrder + user_id, {
            headers: {
                'Authorization': `Bearer ${userToken}`  
            }
           
        });
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getSingleOrderEnquiry = async (order_id) => {
    try {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        let userToken = null;
        if (isAuthenticated && isAuthenticated === 'true') {
            userToken = JSON.parse(localStorage.getItem('userToken'))
        }
        let result = await api.get(Apis.GetSingleOrderEnquiry + order_id, {
            headers: {
                'Authorization': `Bearer ${userToken}`  
            }
           
        });
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const exportedObject = {
    createUserOrder,
    getUserOrder,
    getSingleOrderEnquiry
};
export default exportedObject;