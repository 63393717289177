import React from 'react'
import { Redirect, Route } from 'react-router-dom'
const Protected = ({component: Cmp,...rest}) => (
  
    <Route
    {...rest}
        render={(props)=>{
            let isAuthenticated = localStorage.getItem('isAuthenticated');
            if(isAuthenticated && isAuthenticated === 'true'){
                var userdata = JSON.parse(localStorage.getItem('userData'))
                console.log(userdata.role_id)
                var userRole_id = parseInt(userdata.role_id);

                if(userRole_id === 2){

                 return <Cmp {...props} />
                }else{
                    return <Redirect to="/login" />
                }

            }else{
                console.log(window.location.pathname)
                return <Redirect
            to={{
            pathname: "/login",
            state: { pre_path: window.location.pathname }
          }}
        />
            }
        }}
    />
)
export default Protected; 