import React, { Component } from 'react'
import Breadcrumb from '../common/Breadcrumb'
import FaqItems from './page-sections/faqs/FaqItems'
import { GetPages } from '../../helper'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Faqs",
        page_path: "/faqs",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class FaqPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: [],
            isLoading: true

        }
    }

    async componentDidMount() {
        let resp = await GetPages.getFaqs();
        if (resp) {
            this.setState({
                tabs: resp.faq_all,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    render() {
        const { isLoading, tabs } = this.state;
        document.title = 'Faqs'; 
        document.getElementsByTagName("META")[3].content='Faqs';
        document.getElementsByTagName("META")[4].content='Faqs';
        return (
            <div className="content-wrapper">
                <Breadcrumb BreadcrumbData={BreadcrumbData} />
                <div className="faq-section section-padding">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>FAQs</h2>
                            <div className="border-bottom-line">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="faq-box">
                            {

                                isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader" /></div>

                                    : <>

                                        {
                                            tabs.map((tab, index) => {
                                                return (
                                                    <><h1 className="faq_cat_title">{tab.title}</h1>
                                                        <FaqItems tabs={tab.faqs} />
                                                    </>
                                                )

                                            })
                                        }

                                    </>

                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
