import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Lanyard Printing",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class LanyardPrinting extends Component {
    render() {
        document.title = 'lanyard printing'; 
        document.getElementsByTagName("META")[3].content='lanyard printing';
        document.getElementsByTagName("META")[4].content='lanyard printing';
        return (
            <>
                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="container">
                        <div className="page-desc">
                            <div className="section-title text-center">
                                <h2>Lanyard Printing</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                            </div>
                            <div className="container pantone-text">
                            <p className="info-msg">
                            How to make your order for lanyard printing </p>
                            <br/>
                            <div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/lanyard-dye-sublimation-printing-dbs.jpg"} alt="product-loader"/>
</div>
  <br/>                            
<p>Lanyard Printing is originally an accessory, a Cord or Strap to be used in army service, for securing important things like whistles or pistols.</p>

<p>Since long time ago, lanyard printing started to act as one of the very useful accessories in our lives. Rather be used as a military weapon, lanyards are now an important accessory to easily hold on to objects such as ID cards, handphones, and USB drives in a way that keeps them secured to you. While still allowing easy access for use or display.</p>
<br/>
<div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/multicolor-lanyard.jpg"} alt="product-loader"/>
</div>
<br/>
<p>Luminous Printing is not just a t-shirt printing and corporate gift supplier Singapore company. We do also provide lanyard printing services in Singapore, where our manufacturing office is located at Ang Mo Kio industrial area.</p>

<p>So, our lanyard printing service has a short turn lead time. We only need 5-7 working days for any customization order for lanyards.</p>

<p>We also look more into our customer requirements with 50pcs MOQ. Somehow, we have many color choices for a lanyard and the accessories to be attached to the lanyards.</p>

<p>Luminous Printing offers a wide variety of Lanyards options with our usual array of customization choices. The Lanyards can be print with customers’ company’s logo, wording, event or function message, anything that you want to be included.</p>

<p>The customer is required to select their accessories packages like the colors, the material of lanyard, Clips, and Pins. You are certain to find one that can fulfill a practical function for its users who will be happy to connect it to their personal belongings.</p>
<br/>
<h2>Worry about the costing for Singapore base local production?</h2>
<p>Check with us. I believe you may get the cheapest quotation amongst the local companies.</p>

<p>Lastly, Luminous printing is always happy to serve our clients and provide our best lanyard printing service in Singapore.</p>
<br/>
<div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/lanyard-dye-sublimation-printing-inside-microsoft.jpg"} alt="product-loader"/>
</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
