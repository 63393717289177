import React from 'react'
import Breadcrumb from '../../common/Breadcrumb';
import { Link, Redirect} from "react-router-dom";
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Enquiry Responce",
        page_path: "/enquiry-reponce",
        icon: "ews ewse_gaphicsdes",
    }
]
export default function EnquiryResponce(props) {
  
  if(!props.location.state){
    return <Redirect to="/"/>
  }
    return (
        <div className="content-wrapper ">
          <Breadcrumb BreadcrumbData={BreadcrumbData} />
          <div className="responce-box-outer">
        <div className="responce-box">
          <h2>✔️ Thank You For Your Enquiry!</h2>  
           <p>✅ We have successfully received your enquiry at Luminous Printing! We’ll reply within 24 business hours!</p>
           <p>⚡ For urgent enquiries, please contact us directly to check feasibility!</p>
        <div className="browsmore">
        <Link to="/" className="cart-more-product">Continue Browsing</Link>
        </div>
          
         </div>
         </div>
         </div>
    )
}
