import React, { Component } from 'react'
import { GetUserOrder } from '../../../helper'
import UserEnquiriesItems from  './UserEnquiriesItems'
import OrderEnqiryItems from './OrderEnqiryItems'
import WarningImage from '../../common/warning.png'
export default class UserEnquiries extends Component {
    constructor(){
        super();
        this.state={
            user_enquiries:[],
            isLoading:false,
            enquiry_details:null,
            isEnquiryDetails:false
        }
        this.onViewOrderEnquiry = this.onViewOrderEnquiry.bind(this);
        this.goBack = this.goBack.bind(this);
        
    }

    async componentDidMount(){
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.setState({
            isLoading:true
        });
        let resp = await GetUserOrder.getUserOrder(userData['id']);
        if(resp){
        this.setState({
            user_enquiries:resp.enquiry_orders,
            isLoading:false
        });
       
        }
    }
    async onViewOrderEnquiry(order_id){
    let resp = await GetUserOrder.getSingleOrderEnquiry(order_id);
    if(resp){
    this.setState({
        enquiry_details:resp.enquiry_order,
        isLoading:false,
        isEnquiryDetails:true
    });
   
    }
    
    }
    goBack(){
        this.setState({
            enquiry_details:null,
            isEnquiryDetails:false
        });
    }

    render() {
        const{user_enquiries, isLoading, enquiry_details, isEnquiryDetails} = this.state;
        return (
            <div className="auth-page-wrapper">
                <h3>{ !isEnquiryDetails ? 'User Enquiries':'Ordered Enquiries'}</h3>
                <div className="account-update-form">
                {
                        isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>

                        : <>
                        {
                            user_enquiries.length === 0 ? <div className="wanring-box"><img src={WarningImage} alt="warning" /> <p>No Enquiry!</p></div> :
                           
                        <div className="row">
                        {
                            !isEnquiryDetails ? 
                            user_enquiries.map((item, index) => {
                                    return <UserEnquiriesItems key={index} item={item} onViewOrderEnquiry={this.onViewOrderEnquiry}/>
                                }) 
                                : <> { enquiry_details.map((item, index) => {
                                    return <OrderEnqiryItems key={index} item={item}/>
                                }) }
                                <button className="goback-btn" onClick={this.goBack}>Go Back</button>
                                </>
                                
                            }
                            
                        </div>
                }
                </>
            }
                           
                </div>
            </div>
        )
    }
}
