import React from 'react';
import HomeBanner from './HomeBanner';
import CategoryProduct from '../pages/page-sections/category-product/CategoryProduct';
import ServiceSection from './page-sections/ServiceSection';
import QuatationSection from './page-sections/QuatationSection';
import PrintingServices from './page-sections/PrintingServices';
import ServiceOverview from './page-sections/ServiceOverview';
import Faqs from './page-sections/Faqs';
import AboutSection from './page-sections/AboutSection'


const Home = () => {
  document.title ="Welcome to luminous printing"; 
  document.getElementsByTagName("META")[3].content="Buy T-shirt with printing in India.";
    return (
      <>
      <HomeBanner/>
      <CategoryProduct/>
      <ServiceSection/>
      <AboutSection/>
      <QuatationSection bgcolor={'quatatiion-section'}/>
      <PrintingServices/>
      <ServiceOverview/>
      <Faqs/>
      </>
    )
}

export default Home
