import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import QuatationSection from '../page-sections/QuatationSection';
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "How To Order",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class HowToOrder extends Component {
    render() {
        document.title = 'How To Order'; 
        document.getElementsByTagName("META")[3].content='How To Order';
        document.getElementsByTagName("META")[4].content='How To Order';
        return (
            <>
                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />


                    <div className="container">

                        <div className="page-desc">
                            <div className="section-title text-center">
                                <h2>How To Order</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <h4>How to make your order <br />Standard Operation Procedure</h4>
                            </div>
                            <QuatationSection />
                            <div className="container">
                            
                                <div className="step-content">
                                    <div className="step-content-box">
                                        <h3>Step1:</h3>

                                        <p>Get a quotation from our sales representative. Send in your email enquiry to sales.luminousprinting@gmail.com or WhatsApp to 91195104 with the information below.</p>

                                        <p>Select your ready stock t-shirt or corporate gifts from our catalogue Advise the quantity you want to do printing Do attach your artwork, printing method and locations Advise the dateline when you need the t-shirt or corporate gifts</p>


                                        <p>Our sales experts will advise you on the most suitable printing method, providing you with the best package for printing services. Appreciate your patience in waiting to receive your quotation within a day or less.</p>

                                        <p>If you have any budget concern, please do share with us. We ensure that you will get a happy offer with Luminous Printing.</p>
                                    </div>

                                    <div className="step-content-box">
                                        <h3>Step2:</h3>

                                        <p>Confirmation of Order</p>

                                        <p>Do inform us if you would like to confirm your order via email or WhatsApp, our sales representative will send you the official invoice/order form with the bank details. 50% deposit payment is required upon confirmation and 100% full payment only for rush order.</p>

                                    </div>
                                    <div className="step-content-box">
                                        <h3>Step3:</h3>

                                        <p>Approval of design proposal</p>

                                        <p>Once we have received the deposit payment, our designer will send you a design proposal and seek for customer approval. We will only proceed your order mass production once we got approval from customer.</p>
                                        <p>After the design proposal is approved, we are not allowed to do any amendment. But there is still a chance if you able to contact us immediately, and we will check the availability for you.</p>

                                        <br/>
                                        <h4>Lead time:</h4>

                                        <p>Ready stock: 5-7 working days upon confirmation of design proposal and subject to stock availability.</p>

                                        <p>Custom Made Apparel (Made-to-order) : 1 week sample stage + 2 weeks mass production</p>

                                        <p>Rush Order: 1-2 working days (Express Charges Applied)</p>


                                    </div>
                                    <div className="step-content-box">
                                        <h3>Step4:</h3>
                                        <p>Job Complete</p>

                                        <p>You could select your method of delivery. For more information you could refer to our Delivery Information</p>

                                        <p>Any shortage or damage on the T-shirt, the customer must notify us within 48hours.</p>

                                     

                                        <h4> Payment method</h4>

                                        <p>Bank transfer or cross cheque assign to Luminous Printing UOB Current Account Account Number: 701 353 2722</p>

                                        <p>Bank Code / Branch Code : 7375 / 001</p>

                                      
                                        <p>For Pay now, please key in the UEN Number 53351678A and make the payment. If you pay via cash, you could only do on the spot at our office.</p>

                                        <p>For government institution the of the organization, we accept 30days e-invoice via vendors.gov.sg or Gebiz.</p>

                                        <p>**If you require a payment term, do check with our management for approval, we could only accept maximum 30days terms from only government organisation. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

