import React, { Component } from 'react'
import dummyImg from '../../../assets/img/ImageNotFound.svg'

const Layout = ({ url }) => {
	return (
        <div className="product-image-slide">
            <img src={url} alt="" className="hover-zoom"/>
        </div>
	);
}

export default class ProductDetailsCarousel extends Component {
    constructor (props) {
		super(props);
		
		this.state = {
			currentImageIndex: 0
		};
		
		this.nextSlide = this.nextSlide.bind(this);
		this.previousSlide = this.previousSlide.bind(this);
       this.bulletSlide = this.bulletSlide.bind(this);
	}
	
	previousSlide () {
		const lastIndex = this.props.imgUrls.length - 1;
		const { currentImageIndex } = this.state;
		const shouldResetIndex = currentImageIndex === 0;
		const index =  shouldResetIndex ? lastIndex : currentImageIndex - 1;
		
		this.setState({
			currentImageIndex: index
		});
       
	}
	
	nextSlide () {
		const lastIndex = this.props.imgUrls.length - 1;
		const { currentImageIndex } = this.state;
		const shouldResetIndex = currentImageIndex === lastIndex;
		const index =  shouldResetIndex ? 0 : currentImageIndex + 1;

		this.setState({
			currentImageIndex: index
		});
	}
    bulletSlide (index) {
		this.setState({
			currentImageIndex: index
		});
	}

   
    render() {
        return (
            <>
               <div className="carousel">
                {
                    <Layout url={ this.props.imgUrls[this.state.currentImageIndex] ? this.props.imgUrls[this.state.currentImageIndex].image_name : dummyImg }/>	
					
                }
                <ul className="prosuct-carousel-control">
                {this.props.imgUrls.map((item, i) => (
                 <li key={i} onClick={e => this.bulletSlide(i)} className={`carousel-dot-image ${this.state.currentImageIndex === i ? 'active' : ''}`}> <img src={item.image_name} alt=""/></li>
                ))}
                </ul>
			</div> 
            </>
        )
    }
}
