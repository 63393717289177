import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import { GetRecentProjects } from '../../../helper'
import RecentProjectItems from './RecentProjectItems'
import RecentProjectModal from './RecentProjectModal'
import parse from 'html-react-parser'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Recent Projects",
        page_path: "/recent-projects",
        icon: "ews ewse_gaphicsdes",
    }

]
export default class RecentProjects extends Component {
    constructor() {
        super();
        this.state = {
            recentProjects: [],
            isLoading: true,
            limit: 8,
            show: false,
            big_image: null,
            image_tilte: null,
            description:''
        }
        this.onLoadMore = this.onLoadMore.bind(this);
        this.onViewImage = this.onViewImage.bind(this);
        this.hideModal = this.hideModal.bind(this);

    }
    onLoadMore() {
        this.setState({
            limit: this.state.limit + 8
        });
    }



    hideModal = () => {
        this.setState({ show: false });
    };

    onViewImage(img_id) {
        var imgID = parseInt(img_id);
        const project = this.state.recentProjects.find(item => item.id === imgID)

        this.setState({
            big_image: project.big_image,
            image_tilte: project.title,
            description: project.description,
            show: true,
        });

    }
    async componentDidMount() {
        let resp = await GetRecentProjects.getAllRecentProjects();
        if (resp) {
            this.setState({
                recentProjects: resp.home_recent_projects,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    render() {
        const { recentProjects, isLoading, big_image, image_tilte, description } = this.state;
        const showProduct = recentProjects.slice(0, this.state.limit);

        return (
            <>

                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />


                    <div className="container ">

                        <div className="page-desc">

                            <div className="section-title text-center">
                                <h2>Recent Projects</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div className="page-desc">
                                {
                                    isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader" /></div>

                                        :
                                        showProduct.length === 0 ?
                                            <div className="data-warning-box">
                                                <img src={process.env.PUBLIC_URL + "../assets/img/iconWarning.png"} alt="product-loader" />
                                                <p className="data-warning">  No data available</p>
                                            </div>
                                            :
                                            <>
                                                <div className="row">
                                                    {
                                                        showProduct.map((item, index) => {
                                                            return <RecentProjectItems key={index} item={item} onViewImage={this.onViewImage} />
                                                        })
                                                    }
                                                </div>
                                                <div className="view-more-transparent">
                                                    {showProduct.length === recentProjects.length ? null : <button onClick={this.onLoadMore}>View More</button>}
                                                </div>
                                            </>

                                }
                            </div>

                        </div>
                    </div>
                </div>
                <RecentProjectModal show={this.state.show} handleClose={this.hideModal}>
                    <img src={big_image} alt="" />
                    <h3>{image_tilte}</h3>
                    <div className="recentproject-desc">{parse(description)}</div>
                </RecentProjectModal>
            </>
        )
    }
}
