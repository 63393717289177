 const lanyard_material_options = [
    {
        text: "Full-Colour Printed Lanyard",
        image: "lm1.jpg",
        value: "Full-Colour Printed Lanyard"
    },
    {
        text: "Smooth Nylon Lanyard",
        image: "lm2.jpg",
        value: "Smooth Nylon Lanyard"
    },
    {
        text: "Polyester Lanyard",
        image: "lm3.jpg",
        value: "Polyester Lanyard"
    },
    {
        text: "Woven Lanyard",
        image: "lm4.jpg",
        value: "Woven Lanyard"
    },
    {
        text: "Tubular Lanyard",
        image: "lm5.jpg",
        value: "Tubular Lanyard"
    },
    {
        text: "Dual Hooks Lanyard",
        image: "lm6.jpg",
        value: "Dual Hooks Lanyard"
    }
]


export default lanyard_material_options;
 
