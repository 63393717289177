import React, { Component } from 'react'
import { GetUserLogin, Alerts } from '../../../helper';
export default class ChangePassword extends Component {
    constructor(){
        super();
        this.state={
            user_id:null,
            current_password:null,
            new_password:null,
            confirm_password:null,
            validationErrors:[],
            isLoading:false
        }
    }
    async componentDidMount(){
      const userData = JSON.parse(localStorage.getItem('userData'))
      const user_id = userData['id']
      this.setState({
        user_id:user_id
    })
    }
    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({[name]: value });
    }
    handleUpdateSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading:true
        })
        const {user_id, current_password, new_password, confirm_password} = this.state;
        let data = { user_id: user_id,current_password: current_password, new_password: new_password, confirm_password: confirm_password}
        let resp = await GetUserLogin.getPasswordUpdate(data);
        if(resp){
           if(resp.status === true){
            Alerts.alertMessage("Password updated successfully.", "Nice !", "success");
             this.setState({
                validationErrors:[],
                isLoading:false
            })
           
           }
           if(resp.status === false){
               this.setState({ 
                   validationErrors : resp.errors,
                   isLoading:false
                });
           }
           if(resp.status === 'MatchValid'){
            Alerts.alertMessage("Current Password does not match.", "Sorry !", "danger");
            this.setState({ 
                isLoading:false
             });
        }
        }
        
    }
    render() {
        const {current_password, new_password, confirm_password, validationErrors, isLoading} = this.state;
        return (
            <div className="auth-page-wrapper">
                <h3>Change Password</h3>
                <div className="account-update-form">
                    <form onSubmit={this.handleUpdateSubmit}>
                        <div className="form-group row">
                          <div className="col-6">
                              <input type="password" className="form-control" placeholder="Current Password" name="current_password" value={current_password} onChange={this.handleChange}/>
                              <span className="error-help">{validationErrors['current_password']}</span>
                          </div>
                          <div className="col-6">
                              <input type="password" className="form-control" placeholder="New Password" name="new_password" value={new_password} onChange={this.handleChange}/>
                              <span className="error-help">{validationErrors['new_password']}</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-6">
                              <input type="password" className="form-control" placeholder="Confirm Password" name="confirm_password" value={confirm_password} onChange={this.handleChange}/>
                              <span className="error-help">{validationErrors['confirm_password']}</span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-6">
                              <button type="submit" className="primary-btn color-white"> { isLoading ? "Please wait..":"Update" }  </button>
                          </div>
                         
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
