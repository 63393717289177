import React, { Component } from 'react'
import Breadcrumb from '../common/Breadcrumb'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PrintingMethodsItems from './page-sections/printing-methods/PrintingMethodsItems'
import {GetPages} from '../../helper'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Printing Methods",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class PrintingMethods extends Component {
    constructor(){
        super();
        this.state={
            tshirtPrintingMethods:null,
            corporateGiftPrintingMethods:null,
            isLoading:true
        }
    }
    async componentDidMount() {
        let resp = await GetPages.getAllPrintingMethods();
        if(resp){
            const tshirtPrintingMethods = resp.printing_method.filter(item => item.printing_method_type === '1');
            const corporateGiftPrintingMethods = resp.printing_method.filter(item => item.printing_method_type === '0');
            this.setState({
                tshirtPrintingMethods: tshirtPrintingMethods,
                corporateGiftPrintingMethods:corporateGiftPrintingMethods,
                isLoading:false
            })
        }else{
            this.setState({
             isLoading:false
         }) 
        }
      }
    render() {
        const { tshirtPrintingMethods,corporateGiftPrintingMethods,isLoading } = this.state;
       
        console.log(tshirtPrintingMethods);
        console.log(corporateGiftPrintingMethods);
        return (
            <div className="content-wrapper">
                <Breadcrumb BreadcrumbData={BreadcrumbData} />
                <div className="container">
              
                    <div className="page-desc">
                    <div className="section-title text-center">
                    <h2>Printing Methods</h2>
                    <div className="border-bottom-line">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="printing-methods">
                    <Tabs>
                                <TabList>
                                    <Tab>👕 T-shirt Printing Methods</Tab>
                                    <Tab>🎁 Corporate Gifts Printing Service Methods</Tab>
                                </TabList>
                                
                                <TabPanel>
                                <div className="tab-body">
                                <div className="row">
                            {
                                !isLoading ?
                                tshirtPrintingMethods.map((item, index) => {
                                    return <PrintingMethodsItems key={index} item={item} />
                                }) 
                                :<div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>
                            }


                        </div>
                                </div>
                                
                                </TabPanel>
                                <TabPanel>
                                <div className="tab-body">
                                <div className="row">
                            {
                                !isLoading ?
                                corporateGiftPrintingMethods.map((item, index) => {
                                    return <PrintingMethodsItems key={index} item={item} />
                                }) 
                                :<div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>
                            }
                            </div>

                        </div>
                                </TabPanel>
                            </Tabs>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}
