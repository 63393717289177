import React from 'react'


const serviceData = [
    {
        id:1,
        title:"100% Customer Satisfaction",
        desc:"We ensure our customer will get their tshirt or gifts printed in high quality. Hence, quality assurance and quantity check always take place.",
        icon_image:"i-user"
    },
    {
        id:2,
        title:"Affordable & Transparent Pricing",
        desc:"We provide a very competitive pricing that you may compare with others vendors. So, no other hidden cost existed.",
        icon_image:"i-edit"
    },
    {
        id:3,
        title:"100% Custom Made Options",
        desc:"Get your T-Shirt printing and Apparel Customized from Scratch, when you order Large Quantities with us. Create an unique uniform with us now.",
        icon_image:"i-heart"
    },
    {
        id:4,
        title:"Fast Turn Around & Delivery",
        desc:"Shortest lead time upon confirmation and entitle a free delivery for your bulk order.",
        icon_image:"i-star-empty"
    }

]

const ServiceSection = () => {
    return (
        <>
            <div className="service-section section-padding">
                <div className="container">
                    <div className="row">

                    {
                        serviceData.map((item, index) => {
              return (
                <div className="col-3 col-sm-12">
                            <div className="service-box">
                                <div className="service-icon">
                                    <i className={ item.icon_image }></i>
                                </div>
                                <div className="service-content">
                                    <h4>{ item.title }</h4>
                                    <p>{ item.desc }</p>
                                </div>
                            </div>
                        </div>
              )
            })
                    }
                        
                    </div>
                </div>
            </div>
  
        </>
    )
}

export default ServiceSection
