import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CategoryItems from './CategoryItems'
import LoaderImage from '../../common/data-loader.gif'
import WarningImage from '../../common/warning.png'
export default class CategoryProduct extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      mainCategoryProducts: [],
      shortedProducts: [],
      subcategories: [],
      isLoading: true,
      limit: 8,
      category_name: '',
      parent_category_name: '',
      category_slug: '',
      parent_category_slug: '',
      meta_title: '',
      meta_desc: '',
      meta_keywords: ''
    }
    this.onLoadMore = this.onLoadMore.bind(this);
  }
  onLoadMore() {
    this.setState({
      limit: this.state.limit + 8
    });
  }
  fetchProducts = () => {
    let slug = this.props.match.params.slug;
    fetch(`http://luminousapi.articloo.com/api/category-product/${slug}`)
      .then((response) => response.json())
      .then(resp => {
        if(resp.category_status === false){
          return this.props.history.push('/not-found');
        }
        if (resp.category_status === 1) {
          this.setState({
            subcategories: resp.subcategories,
            mainCategoryProducts: resp.products,
            category_name: resp.category.title,
            category_slug: resp.category.slug,
            meta_title: resp.category.meta_title,
            meta_desc: resp.category.meta_desc,
            meta_keywords: resp.category.meta_keywords,
            isLoading: false
          });
        }

        if (resp.category_status === 2) {
          this.setState({
            products: resp.products,
            shortedProducts: resp.shortedProduct,
            category_name: resp.category.title,
            category_slug: resp.category.slug,
            meta_title: resp.category.meta_title,
            meta_desc: resp.category.meta_desc,
            meta_keywords: resp.category.meta_keywords,
            isLoading: false
          });
        }


        if (resp.category_status === 3) {
          this.setState({
            products: resp.products,
            shortedProducts: resp.shortedProduct,
            category_name: resp.category.title,
            category_slug: resp.category.slug,
            meta_title: resp.category.meta_title,
            meta_desc: resp.category.meta_desc,
            meta_keywords: resp.category.meta_keywords,
            isLoading: false
          });
        }
      })
      .catch(function() {
        console.log("error");
    });
  }
  componentDidMount() {
    this.fetchProducts();
  }

  componentDidUpdate(prevProps) {

    if (prevProps.match.params !== this.props.match.params) {
      this.setState({
        isLoading: true,
        limit: 8
      });
      this.fetchProducts();
    }
  }



  render() {
    const { products, mainCategoryProducts, shortedProducts, category_name, category_slug, isLoading, subcategories, meta_title, meta_desc, meta_keywords } = this.state;
   const showProduct = products.slice(0, this.state.limit);
    // const showProduct = products;

    const categoryDetails = {
      'category_name': category_name,
      'category_slug': category_slug,
      'parent_category_slug': this.props.match.params.parent_cat,
    }
    document.title = `${meta_title}`;
    document.getElementsByTagName("META")[3].content = `${meta_desc}`;
    document.getElementsByTagName("META")[4].content = `${meta_keywords}`;
    return (

      <div className="category-listing">
        <div className="category-title">
          <h2>{category_name}</h2>
        </div>
        <div className="category-listing-items">
          {
            isLoading ? <div className="listing-loader"><img src={LoaderImage} alt="product-loader" /></div> :
            subcategories.length !== 0 ? subcategories.map((item1, index) => {
              return <div key={index}>
                {
                  item1.products.length !== 0 ? <h3 className="category-name">{item1.title}</h3> :
                    index === 0 ? <div className="wanring-box"><img src={WarningImage} alt="warning" /> <p>Product Not Found !</p></div> : null
                }
                <div className="row multiple">
                  {
                    item1.products.map((item, index) => {
                      if (index < 4) {
                        return <CategoryItems key={index} item={item} category={categoryDetails} categorySlug={item1.slug} />
                      }

                    })
                  }
                </div>
                {
                  item1.products.length > 4 ? <div className="view-all-products"> <Link to={'/category/' + category_slug + '/' + item1.slug}>View all {item1.title} <i className="i-angle-right"></i></Link> </div> : null
                }
              </div>
            }) : subcategories.length === 0 && showProduct.length !== 0 ? <>
              <div className="row multiple">
                {shortedProducts.length !== 0 ? <>
                  {
                    shortedProducts.map((item, index) => {
                      return <CategoryItems key={index} item={item} category={categoryDetails} />
                    })
                  }
                </> : null}

                {
                  showProduct.map((item, index) => {
                    return <CategoryItems key={index} item={item} category={categoryDetails} />
                  })
                }

              </div>
              <div className="view-more-transparent">
                      {showProduct.length === products.length ? null : <button onClick={this.onLoadMore}>View More { category_name }</button>}
                    </div>
            </> : null
          }
        </div>
      </div>

    )
  }
}
