import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import { GetPortfolios } from '../../../helper'
import PortfolioItems from './PortfolioItems'
import PortfolioModal from './PortfolioModal'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Recent Projects",
        page_path: "/recent-projects",
        icon: "ews ewse_gaphicsdes",
    }

]
export default class PortfolioListing extends Component {
    constructor() {
        super();
        this.state = {
            portfolios: [],
            isLoading: true,
            limit: 12,
            show: false,
            big_image: null,
            image_tilte: null
        }
        this.onLoadMore = this.onLoadMore.bind(this);
        this.onViewImage = this.onViewImage.bind(this);
        this.hideModal = this.hideModal.bind(this);

    }
    onLoadMore() {
        this.setState({
            limit: this.state.limit + 12
        });
    }



    hideModal = () => {
        this.setState({ show: false });
    };

    onViewImage(img_id) {
        var imgID = parseInt(img_id);
        const portfolio = this.state.portfolios.find(item => item.id === imgID)

        this.setState({
            big_image: portfolio.big_image,
            image_tilte: portfolio.title,
            show: true,
        });

    }
    async componentDidMount() {
        let resp = await GetPortfolios.getAllPortfolios();
        if (resp) {
            this.setState({
                portfolios: resp.portfolio_items,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    render() {
        const { portfolios, isLoading, big_image, image_tilte } = this.state;
        const showProduct = portfolios.slice(0, this.state.limit);

        return (
            <>

                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />


                    <div className="container ">

                        <div className="page-desc">

                            <div className="section-title text-center">
                                <h2>Recent Projects</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div className="page-desc">
                                {
                                    isLoading ? <div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader" /></div>

                                        :
                                        showProduct.length === 0 ?
                                            <div className="data-warning-box">
                                                <img src={process.env.PUBLIC_URL + "../assets/img/iconWarning.png"} alt="product-loader" />
                                                <p className="data-warning">  No data available</p>
                                            </div>
                                            :
                                            <>
                                                <div className="row">
                                                    {
                                                        showProduct.map((item, index) => {
                                                            return <PortfolioItems key={index} item={item} onViewImage={this.onViewImage} />
                                                        })
                                                    }
                                                </div>
                                                <div className="view-more-transparent">
                                                    {showProduct.length === portfolios.length ? null : <button onClick={this.onLoadMore}>View More</button>}
                                                </div>
                                            </>

                                }
                            </div>

                        </div>
                    </div>
                </div>
                <PortfolioModal show={this.state.show} handleClose={this.hideModal}>
                    <img src={big_image} alt="" />
                    <h3>{image_tilte}</h3>
                </PortfolioModal>
            </>
        )
    }
}
