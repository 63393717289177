import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAllMenus = async () => {
    try {
        let result = await api.get(Apis.GetAllMenus);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCatelogueMenus = async () => {
    try {
        let result = await api.get(Apis.GetAllCatelogueMenus);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllPrintingMethodMenus = async () => {
    try {
        let result = await api.get(Apis.GetAllPrintingMethodsMenu);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const exportedObject = {
    getAllMenus,
    getCatelogueMenus,
    getAllPrintingMethodMenus
};
export default exportedObject;