import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAllProducts = async () => {
    try {
        let result = await api.get(Apis.GetAllProductList);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getBestSallerProducts = async () => {
    try {
        let result = await api.get(Apis.GetBestSallerProductsList);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getNewArraivalProducts = async () => {
    try {
        let result = await api.get(Apis.GetArrivalProductsList);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const exportedObject = {
    getAllProducts,
    getBestSallerProducts,
    getNewArraivalProducts
};
export default exportedObject;