import React from 'react'

const QuatationSection = ({bgcolor}) => {
    return (
        <>


             <div className={"section-padding " + bgcolor}>
                <div className="container">
                <div className="row">
                                    <div className="col-3 col-sm-12">
                                        <div className="quatatiion-box">
                                            <div className="quatatiion-inner-box">
                                                <div className="quatatiion-top">
                                                    <div className="quatatiion-icon">
                                                        <i className="i-user"></i>
                                                    </div>
                                                    <div className="quatatiion-content">
                                                        <h4>Get a Quotation </h4>
                                                        <ol>
                                                            <li>Select your ready stock tshirt or corporate gifts from our catalogue</li>
                                                            <li>Advice the quantity & Deadline</li>
                                                            <li>Do attached your artwork, printing method and locations</li>
                                                        </ol>
                                                       
                                                    </div>

                                                </div>
                                                <div className="quatatiion-bottom bx-color-1">
                                                    <h1>Step <span>01</span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-12">
                                        <div className="quatatiion-box">
                                            <div className="quatatiion-inner-box">
                                                <div className="quatatiion-top">
                                                    <div className="quatatiion-icon">
                                                        <i className="i-user"></i>
                                                    </div>
                                                    <div className="quatatiion-content">
                                                        <h4>Confirmation of Order</h4>
                                                        <ol>
                                                            <li>50% deposite payment is required upon confirmation and 100% full payment only for rush order</li>
                                                            <li>Order form to be signed and endorsement for government organisation</li>
                                                        </ol>
                                                    </div>

                                                </div>
                                                <div className="quatatiion-bottom bx-color-2">
                                                    <h1>Step <span>02</span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-12">
                                        <div className="quatatiion-box">
                                            <div className="quatatiion-inner-box">
                                                <div className="quatatiion-top">
                                                    <div className="quatatiion-icon">
                                                        <i className="i-user"></i>
                                                    </div>
                                                    <div className="quatatiion-content">
                                                        <h4>Approval of Design Proposal</h4>
                                                        <ol>
                                                            <li>Ones Payment is make, we will send design proposal to seek for customer approval.</li>
                                                            <li>Once approved, will proceed to mass production. No Amentment will be be allow.</li>
                                                        </ol>
                                                    </div>

                                                </div>
                                                <div className="quatatiion-bottom bx-color-3">
                                                    <h1>Step <span>03</span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-12">
                                        <div className="quatatiion-box">
                                            <div className="quatatiion-inner-box">
                                                <div className="quatatiion-top">
                                                    <div className="quatatiion-icon">
                                                        <i className="i-user"></i>
                                                    </div>
                                                    <div className="quatatiion-content">
                                                        <h4>Job Completed (Self-Collection or Delivery)</h4>
                                                        <ol>
                                                            <li>50% balnce payment will be collect upon collection or delivery.</li>
                                                            <li>Delevery fees apply:For more information please refer to our delivery information</li>
                                                        </ol>
                                                    </div>

                                                </div>
                                                <div className="quatatiion-bottom bx-color-4">
                                                    <h1>Step <span>04</span></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                   {/* <div className="row">
                       <div className="col-3 col-sm-12">
                           <div className="quatatiion-box">
                           <div className="quatatiion-inner-box">
                           <div className="quatatiion-top">
                           <div className="quatatiion-icon">
                                    <i className="i-user"></i>
                                </div>
                                <div className="quatatiion-content">
                                    <h4>Get a Quatation</h4>
                                    <p>We ensure our customer will get their tshirt or gifts printed in high quality. Hence, quality assurance and quantity check always take place.</p>
                                    <p>We ensure our customer will get their tshirt or gifts printed in high quality. Hence, quality assurance and quantity check always take place.</p>
                                </div>
                                
                                </div>
                           <div className="quatatiion-bottom bx-color-1">
                                <h1>Step <span>01</span></h1>
                                </div>
                           </div>
                           </div>
                       </div>
                   </div> */}
                 </div>
              </div>
        </>
    )
}

export default QuatationSection
