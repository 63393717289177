import {connect} from 'react-redux'
import Cart from '../components/pages/enquiries/Cart'
import {removeToCart} from '../services/actions/actions'
const mapStateToProps=state=>({
     data:state.cardItems
})
const mapDispatchToProps=dispatch=>({
     removeToCartHandler:()=>dispatch(removeToCart())

})
export default connect(mapStateToProps,mapDispatchToProps)(Cart)