import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Size Chart Information",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class SizeChartInfo extends Component {
    render() {
        document.title = 'Size Chart Information'; 
        document.getElementsByTagName("META")[3].content='Size Chart Information';
        document.getElementsByTagName("META")[4].content='Size Chart Information';
        return (
            <>
                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="container">
                        <div className="page-desc">
                            <div className="section-title text-center">
                                <h2>Size Chart Information</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                            </div>
                            <div className="container">

                                <p className="info-msg">
                                All sizes measurement are approximate (+- 3/4inch) tolerance due to bulk manufacturing of the tshirts</p>

                               <h3>How to use Size Chart</h3> 

<p>How to measure by using t shirt Size Chart?</p>

<p>Refer to the size chart that attached in the individual catalogue. Find the Code number for the item you selected.</p>

<p>Then, Put your apparel on a flat table or floor, use the below very easy and basic guideline to measure.</p>

<p>A – Half Chest: Measure from Armpit point to Armpit point<br/>
B – Body Length: Measure from the Top to the Bottom</p>

<p>If you are not sure about what size that you are wearing, you may try to measure your current wearing t-shirt that fits you, and match or compare to the size chart below.</p>

<h4>Guideline</h4>
<p>There are two types of measurement in INCHES AND CM </p>

<div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/size-chart-page.png"} alt="product-loader"/>
</div>

<div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/size-chart-page1.png"} alt="product-loader"/>
</div>

<p>**Shoulder length and Sleeve length only applicable for certain series.</p>

 

<p><strong>Do Contact us at sales.luminousprinting@gmail.com or +65 91195104 , if you find any difficutlies to measure the sizes. </strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
