import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Pantone Color Chart",
        page_path: "#",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class PantoneColorChart extends Component {
    render() {
        document.title = 'Pantone Color Chart'; 
        document.getElementsByTagName("META")[3].content='Pantone Color Chart';
        document.getElementsByTagName("META")[4].content='Pantone Color Chart';
        return (
            <>
                <div className="content-wrapper bg-light-gray">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="container">
                        <div className="page-desc">
                            <div className="section-title text-center">
                                <h2>Pantone Color Chart</h2>
                                <div className="border-bottom-line">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                            </div>
                            <div className="container pantone-text">
                            <p className="warning-msg">
                                All sizes measurement are approximate (+- 3/4inch) tolerance due to bulk manufacturing of the tshirts</p>
                            
                               <h3> What is Pantone Color Chart?</h3>
<p>The PANTONE color chart is known worldwide as the standard language for color communication from designer to manufacturer to retailer to customer.</p>

<h3>Why use Pantone Color chart Solid coated for our silkscreen t shirt printing or corporate gift printing?</h3>
<p>There are many different types of Pantone Color chart as a reference for a designer. We always use solid coated color chart for t shirt printing or gift printing industry in Singapore. The reason is the color can be easily mixed and match to get the exact tone base on the formula provided officially.</p>

<h3>How many color available in pantone color chart solid coated ?</h3>
<p>It is more than 800 colors can be selected. But this pantone color chart is only a reference for a customer to select. When coming to printing, we are hand stir by our production worker, and it might have at least +- 10% color tolerance.</p>
<div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/pantone-color.png"} alt="product-loader"/>
</div>
<br/>
<h3>How is this chart works on Silkscreen Printing?</h3>
<p>We called it Color Seperation. This is the process of making the high-resolution artwork and separating the colors to facilitate the creation of the individual printing blocks tooling.
Below is the sample for 3 colors silkscreen printing. </p>
<div className="page-image-wrapper">
<img src={process.env.PUBLIC_URL + "../assets/img/pantone-color-1.png"} alt="product-loader"/>
</div>
<br/>
<h3>What if my artwork is not in solid colors? Like picture or gradient effect?</h3>
<p>In this case, Silkscreen Printing will not the ideal printing method to be used for your artwork. Some of the gradient effects can still be done by using silkscreen, but it is very subjective.</p>

<p>However, you may check with our sales team to assist you. For a safer option, we would always recommend to our customer to do their picture or gradient effect printing. Either using Digital Heat Transfer or alternative a more expensive method called Direct-To-Garment. These both are able to create so-called full colors printing.</p>


                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
