
const API_URL = "http://luminousapi.articloo.com";



const Apis = {
  //product api
  UserLogin:`${API_URL}/api/login`,
  UserGoogleLogin:`${API_URL}/api/login/verify-google-token`,
  GetUserRegsiter:`${API_URL}/api/register`,
  GetCustomerDetails: `${API_URL}/api/user`,
  UpdateCustomerDetails: `${API_URL}/api/update-account`,
  UpdatePassword: `${API_URL}/api/update-password`,
  CreateUserOrder: `${API_URL}/api/order/create`,
  GetUserOrder: `${API_URL}/api/user/enquiries/`,
  GetSingleOrderEnquiry: `${API_URL}/api/user/enquiry/`,
  GetAllProductList: `${API_URL}/api/products`,
  GetAllCategoryList: `${API_URL}/api/categories`,
  GetSearchCategories: `${API_URL}/api/get-categories`,
  GetHomeCategoryList: `${API_URL}/api/home-categories`,
  GetAboutDetails: `${API_URL}/api/get-about-details`,
  GetFaqs:`${API_URL}/api/get-all-faqs`,
  GetHomeFaqs:`${API_URL}/api/get-home-faqs`,
  GetPrintingMethods:`${API_URL}/api/get-printing-methods`,
  GetAllPrintingMethods:`${API_URL}/api/get-all-printing-methods`,
  GetSinglePrintingMethods:`${API_URL}/api/printing-methods/`,
  GetAllPortfolio:`${API_URL}/api/get-portfolio`,
  GetTermCondition :`${API_URL}/api/terms-condition`,
  GetPrivacyPolicy:`${API_URL}/api/privacy-policy`,
  GetLatestPortfolio:`${API_URL}/api/get-latest-portfolio`,
  GetBestSallerProductsList:`${API_URL}/api/get-best-saller-products`,
  GetArrivalProductsList:`${API_URL}/api/get-new-arrivals-products`,
  GetAllMenus:`${API_URL}/api/get-menus`,
  GetAllCatelogueMenus:`${API_URL}/api/get-footer-catelogue-menus`,
  GetAllPrintingMethodsMenu:`${API_URL}/api/get-printing-method-menu`,
  CorporateEnquiry:`${API_URL}/api/submit-corporate-enquiry`,
  LanyardPrintingEnquiry:`${API_URL}/api/submit-lanyard-printing-enquiry`,
  GetAllPosts:`${API_URL}/api/posts`,
  GetSinglePost:`${API_URL}/api/posts/`,
  GetLatestPosts:`${API_URL}/api/latest-post`,

  GetHomepageRecentProjects:`${API_URL}/api/get-home-page-recent-projects`,
  GetAllRecentProjects:`${API_URL}/api/get-home-recent-projects`,

  GetUserLogout:`${API_URL}/api/logout`,
  
};
export { API_URL, Apis };
