import React, { Component } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import { Redirect } from 'react-router-dom'
import Faqs from '../page-sections/Faqs'
import ProductDetailsCarousel from '../../common/carousel/ProductDetailsCarousel'
import Modal from '../../common/modal/Modal'
import ProducTypeAttribute from './ProducTypeAttribute'
import EnquiryModal from '../enquiries/EnquiryModal'

import axios from 'axios';
import "../../common/modal/modal.css"
import { connect } from 'react-redux';
import { addToCart } from '../../../services/actions/cartActions';
import parse from 'html-react-parser';
import LoaderImg from '../../../assets/img/data-loader.gif'

import sample1 from '../../../assets/img/sample1.jpg';
import sample2 from '../../../assets/img/sample2.jpg';
import sample3 from '../../../assets/img/sample3.jpg';
import sample4 from '../../../assets/img/sample4.jpg';
import sample5 from '../../../assets/img/sample5.jpg';


const customprintoption = [
    {
        "id": 1,
        "label": "1 Color Silkscreen Printing (Logo/A4)",
        "value": "1 Color Silkscreen Printing (Logo/A4)",
        "sample_img": sample1,
        "price": 5.6,
        "qt_disc": {
            '20 - 29': 0.0,
            '30 - 39': 1.1,
            '40 - 49': 1.6,
            '50 - 59': 2.0,
            '60 - 69': 2.2,
            '70 - 79': 2.4,
            '80 - 89': 2.55,
            '90 - 100': 2.7
        }
    },
    {
        "id": 2,
        "label": "2 Colors Silkscreen Printing (Logo/A4)",
        "value": "2 Colors Silkscreen Printing (Logo/A4)",
        "sample_img": sample2,
        "price": 7.8,
        "qt_disc": {
            '20 - 29': 0.0,
            '30 - 39': 1.8,
            '40 - 49': 2.6,
            '50 - 59': 3.2,
            '60 - 69': 3.55,
            '70 - 79': 3.8,
            '80 - 89': 4.05,
            '90 - 100': 4.25
        }
    },
    {
        "id": 3,
        "label": "1 Embroidery (Logo)* 7.5x7.5cm & 3 Colors",
        "value": "1 Embroidery (Logo)* 7.5x7.5cm & 3 Colors",
        "sample_img": sample3,
        "price": 5.8,
        "qt_disc": {
            '20 - 29': 0.0,
            '30 - 39': 0.5,
            '40 - 49': 0.8,
            '50 - 59': 1.05,
            '60 - 69': 1.2,
            '70 - 79': 1.3,
            '80 - 89': 1.45,
            '90 - 100': 1.55
        }
    },
    {
        "id": 4,
        "label": "1 Digital Heat Transfer (Logo) * 8cmx8cm",
        "value": "1 Digital Heat Transfer (Logo) * 8cmx8cm",
        "sample_img": sample4,
        "price": 7.3,
        "qt_disc": {
            '20 - 29': 0.0,
            '30 - 39': 1.8,
            '40 - 49': 2.05,
            '50 - 59': 2.8,
            '60 - 69': 2.95,
            '70 - 79': 3.05,
            '80 - 89': 3.2,
            '90 - 100': 3.35
        }
    },
    {
        "id": 5,
        "label": "1 Digital Heat Transfer (A4) * 28cmx20cm",
        "value": "1 Digital Heat Transfer (A4) * 28cmx20cm",
        "sample_img": sample5,
        "price": 9.5,
        "qt_disc": {
            '20 - 29': 0.0,
            '30 - 39': 1.8,
            '40 - 49': 2.05,
            '50 - 59': 2.8,
            '60 - 69': 2.95,
            '70 - 79': 3.05,
            '80 - 89': 3.2,
            '90 - 100': 3.35
        }
    }
];
const quantityList = [
    {
        "id": 1,
        "label": "20 - 29",
    },
    {
        "id": 2,
        "label": "30 - 39",
    },
    {
        "id": 3,
        "label": "40 - 49",
    },
    {
        "id": 4,
        "label": "50 - 59",
    },
    {
        "id": 5,
        "label": "60 - 69",
    },
    {
        "id": 6,
        "label": "70 - 79",
    },
    {
        "id": 7,
        "label": "80 - 89",
    },
    {
        "id": 8,
        "label": "90 - 100",
    },

];


class ProductDetails extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            otherProductModal: false,
            product_base_price: 0.0,
            quantity_discounted_price: 0,
            quantity: '',
            print_type: customprintoption[0].value,
            print_price: customprintoption[0].price,
            custom_print: false,
            finalPrice: 0,
            productData: {},
            isLoading: true,
            isbutton: true,
            leadtime: "5 working days (after artwork approval)",
            delivery: "Self-collect for FREE or opt for Delivery from $20/location!",
            product_category: null,
            product_category_slug: null,
            parent_category: null,
            parent_category_slug: null,
        }
        this.fullDesc = React.createRef()
    }


    scrollTofullDesc = () => window.scroll({
        top: this.fullDesc.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    });


    openModalHandler = (e) => {
        e.preventDefault();
        this.setState({
            show: true
        })
    }
    openEnquiryModal = (e) => {
        e.preventDefault();
        this.setState({
            otherProductModal: true
        })
    }

    closeModalHandler = (e) => {
        e.preventDefault();
        this.setState({
            show: false
        })
    }
    closeEnquiryModal = () => {
      
        this.setState({
            otherProductModal: false
        })
    }

    handleQuanityChange = (e) => {

        const product_quantity = e.target.value;

        if (product_quantity !== '') {
            let printType = this.state.print_type;
            var res = customprintoption.find(function (item) {
                return item.value === printType;
            });
            var result = res.qt_disc;
            var dic_price = 0;
            for (var k in result) {
                if (k === product_quantity) {
                    dic_price = result[k];
                }
            }

            this.setState({
                quantity_discounted_price: dic_price,
                quantity: product_quantity,
                custom_print: true,
                isbutton: false
            }, () => {
                this.calculatePrice();
            });

        } else {
            this.setState({
                quantity_discounted_price: 0,
                product_base_price: this.state.product_base_price,
                quantity: '',
                print_price: customprintoption[0].price,
                custom_print: false,
                isbutton: true
            }, () => {
                this.calculatePrice();
            });
        }


    }
    handleTypeChange = (data) => {
        this.setState({
            print_price: data.product_price,
            print_type: data.product_type,
        }, () => {
            this.calculatePrice();
        });
    }

    calculatePrice() {
        const { product_base_price, print_price, quantity_discounted_price } = this.state;
        let base_price = product_base_price;
        const final_price = (base_price + print_price) - quantity_discounted_price;
        const fprice = parseFloat(final_price).toFixed(2);
        this.setState({
            finalPrice: fprice
        })
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        axios.get(`http://luminousapi.articloo.com/api/product/${params.category_slug}/${params.slug}`)
            .then(res => {
                const resData = res.data.product;


                this.setState({
                    productData: resData,
                    product_base_price: parseFloat(resData.base_price),
                    product_category: res.data.category.title,
                    product_category_slug: res.data.category.slug,
                    parent_category: res.data.parent_category !== null ? res.data.parent_category.title : null,
                    parent_category_slug: res.data.parent_category !== null ? res.data.parent_category.slug : null,
                    isLoading: false
                });

            }).catch((error) => {
                console.log(error)
                this.setState({
                    isLoading: false
                })
            });
    }

    handleCartSubmit(data) {
        this.props.addToCart(data);
        return this.props.history.push('/enquiry-cart');
    }


    render() {

        const { productData, product_category, product_category_slug, parent_category_slug, parent_category, isLoading, product_base_price, custom_print, finalPrice, print_price, print_type, quantity, leadtime, delivery, otherProductModal} = this.state;

        let BreadcrumbData = null;
        if (parent_category === null) {
            BreadcrumbData = [
                {
                    id: 1,
                    page_title: "Home",
                    page_path: "/",
                    icon: "ews ewse_gaphicsdes",
                },
                {
                    id: 2,
                    page_title: productData ? product_category : null,
                    page_path: productData ? "/category/" + product_category_slug : null,
                    icon: "ews ewse_gaphicsdes",
                },

                {
                    id: 3,
                    page_title: productData ? productData.title : null,
                    page_path: productData ? productData.slug : null,
                    icon: "ews ewse_gaphicsdes",
                }
            ]
        } else {
            BreadcrumbData = [
                {
                    id: 1,
                    page_title: "Home",
                    page_path: "/",
                    icon: "ews ewse_gaphicsdes",
                },
                {
                    id: 2,
                    page_title: productData ? parent_category : null,
                    page_path: productData ? "/category/" + parent_category_slug : null,
                    icon: "ews ewse_gaphicsdes",
                },
                {
                    id: 3,
                    page_title: productData ? product_category : null,
                    page_path: productData ? "/category/" + product_category_slug : null,
                    icon: "ews ewse_gaphicsdes",
                },

                {
                    id: 4,
                    page_title: productData ? productData.title : null,
                    page_path: productData ? productData.slug : null,
                    icon: "ews ewse_gaphicsdes",
                }
            ]
        }

        if (isLoading === false && productData === null) {

            // return this.props.history.push('');
            return <Redirect to="/not-found" />
        }



        return (
            <>
                {

                    isLoading ? <div className="content-loading-wrapper">
                        <img src={LoaderImg} alt="product-loader" />

                    </div> :

                        <div className="content-wrapper">

                            {
                                parseInt(productData.other_product) !== 1 ? <>
                                    {this.state.show ? <div className="back-drop" onClick={this.closeModalHandler}></div> : null}

                                    <Modal show={this.state.show}>
                                        <div className="modal-main" style={{ maxWidth: '1024px' }}>
                                            <div className="modal-header">
                                                <p>Size Chart</p>
                                                <span className="close-modal-btn" onClick={this.closeModalHandler}><i className="i-plus"></i></span>
                                            </div>
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <h3>{productData.title}</h3>
                                                    <div className="size-picture">
                                                        <img src={productData.size_chart_image} alt="Luminous Printing" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal> </>
                                    : null
                            }

                            {
                                parseInt(productData.other_product) === 1 ?  <EnquiryModal show={otherProductModal} onClose={ this.closeEnquiryModal } product={ productData }/> : null }


                            <Breadcrumb BreadcrumbData={BreadcrumbData} />

                            <div className="container mt-20">

                                <div className="row">
                                    <div className="col-4 col-sm-12">
                                        <ProductDetailsCarousel imgUrls={productData.product_images} />
                                    </div>
                                    <div className="col-8 col-sm-12">


                                        {
                                            parseInt(productData.other_product) !== 1 ? <>
                                                <div className="product-description">
                                                    <div className="product-short-desc">
                                                        <div className="product-title">
                                                            <h3>{productData.title}</h3>
                                                        </div>
                                                        <div className="tag_category">
                                                            <p>{productData.excerpt} <button type="button" onClick={() => this.scrollTofullDesc()}>Read More</button></p>
                                                        </div>


                                                        <div className="product-size-wieght">
                                                            <p><strong> Material : </strong>{productData.material}</p>
                                                            <p><strong> Weight : </strong> {productData.weight}</p>
                                                            <p><strong>Sizes : </strong> {productData.size_range}   <button className="btn-openModal" onClick={this.openModalHandler}>Size Chart</button></p>
                                                        </div>

                                                        <div className="price-quote">
                                                            <div className="form-content">
                                                                <h3>From ${finalPrice !== 0 ? finalPrice : product_base_price} /pc  <span>(incl. GST)</span></h3>
                                                                <h4>⚡ Instant Price Quote</h4>
                                                                <select onChange={this.handleQuanityChange}>
                                                                    <option value="">Select quantity to get price quote</option>

                                                                    {quantityList.map((item, i) => (
                                                                        <option value={item.label} key={i}>{item.label}</option>
                                                                    ))}
                                                                </select>
                                                                {
                                                                    custom_print ? <ProducTypeAttribute attributeData={customprintoption} onTypeChange={this.handleTypeChange} leadtime={leadtime} delivery={delivery} /> : null
                                                                }

                                                            </div>
                                                            <button disabled={this.state.isbutton ? "true" : ""} className="enquiry-btn" onClick={() => this.handleCartSubmit({
                                                                "id": productData !== null ? productData.id : null,
                                                                "product_title": productData !== null ? productData.title : null,
                                                                "product_image": productData !== null ? productData.product_images[0].image_name : null,
                                                                "product_base_price": product_base_price,
                                                                "final_price": finalPrice,
                                                                "quantity": quantity,
                                                                "print_price": print_price,
                                                                "print_type": print_type,
                                                                "leadtime": leadtime,
                                                                "delivery": delivery,
                                                            })}>Enquiry</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                        <br />
                                        <div ref={this.fullDesc} className="product-description-bottom">
                                            <div className="product-title">
                                                {parseInt(productData.other_product) !== 1 ? <h3>Description</h3> : <h3>{productData.title}</h3>}
                                            </div>
                                            <p>{parse(productData.description)}</p>

                                            {parseInt(productData.other_product) === 1 ? <button className="btn-enquiry-model" onClick={this.openEnquiryModal}>Make an enquiry for this product</button> : null}
                                        </div>



                                    </div>
                                </div>

                            </div>
                            <Faqs />
                        </div>
                }
            </>
        )
    }
}
export default connect(null, { addToCart })(ProductDetails);