import React, { Component } from 'react'
import { GetUserLogin, Alerts } from '../../../helper';
import { withRouter } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';
class Logout extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        }
        this.handleLoginFailure = this.handleLoginFailure.bind(this);
        this.logout = this.logout.bind(this);
    }
    async logout() {
        this.setState({
            isLoading: true
        })
        let resp = await GetUserLogin.logout();
        if (resp) {
            if (resp.status === true) {
                Alerts.alertMessage("You have logout successfully.", "Logout !", "success");
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('userData');
                localStorage.removeItem('userToken');
                this.setState({
                    isLoading: false
                })
                return this.props.history.push('/');
            }
        }
    }
    handleLoginFailure() {
        alert('Failed to log out')
    }
    handleLogout = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })

        let resp = await GetUserLogin.logout();
        if (resp) {
            if (resp.status === true) {
                Alerts.alertMessage("You have logout successfully.", "Logout !", "success");
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('userData');
                localStorage.removeItem('userToken');
                this.setState({
                    isLoading: false
                })
                return this.props.history.push('/');
            }
        }
    };//..... end of revokeAccessToken() .....//
    render() {
        const userData = JSON.parse(localStorage.getItem('userData'))
      
        return (
            <>
                {
                    userData && userData.provider !== null ? <GoogleLogout
                        clientId="815778410908-48d358aujji97hi41hn605gpstsfdqlp.apps.googleusercontent.com"
                        buttonText='Logout'
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="logout-btn">{this.state.isLoading ? 'Please wait..' : 'Logout'}</button>
                        )}
                        onLogoutSuccess={this.logout}
                        onFailure={this.handleLoginFailure}
                    >
                    </GoogleLogout> : <button onClick={this.handleLogout} className="logout-btn">{this.state.isLoading ? 'Please wait..' : 'Logout'}</button>
                }


            </>
        )
    }
}
export default withRouter(Logout);
