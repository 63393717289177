import '../../../assets/css/portfolio-modal.css';

const RecentProjectModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main portfolio-modal">
        {children}
        <button type="button" onClick={handleClose} className="cancel-btn">
          <i className="i-plus"></i>
        </button>
      </section>
    </div>
  );
};
export default RecentProjectModal;
