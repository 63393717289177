import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/404.css'
export default class NotFound extends Component {
    render() {
        return (
            <div className="content-wrapper">
                <div className="mainbox">
                    <h1>404</h1>
                    <div className="msg">Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?<p>Let's go <Link to="/">home</Link> and try from there.</p></div>
                </div>
            </div>
        )
    }
}
