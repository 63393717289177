import React, { Component } from 'react'
import { GetPages } from '../../helper'
import Breadcrumb from '../common/Breadcrumb'
import parse from 'html-react-parser'
const BreadcrumbData = [
    {
        id:1,
        page_title:"Home",
        page_path:"/",
        icon:"ews ewse_gaphicsdes",
    },
    {
        id:2,
        page_title:"About us",
        page_path:"/about",
        icon:"ews ewse_gaphicsdes",
    }
]
export default class About extends Component {
    constructor(){
        super();
        this.state={
          isLoading:true,
          pageDetails:null
        }
      }
      async componentDidMount(){
      
        let resp = await GetPages.getAboutDetails();
        if(resp){
            this.setState({
                pageDetails: resp.aboutDetails,
                isLoading:false
            })
        }else{
            this.setState({
             isLoading:false
         }) 
      }
    }
    render() {
        const {pageDetails, isLoading} = this.state; 
        document.title = pageDetails !== null ? pageDetails.meta_title: ''; 
        document.getElementsByTagName("META")[3].content= pageDetails !== null ? pageDetails.meta_description: '';
        document.getElementsByTagName("META")[4].content= pageDetails !== null ? pageDetails.meta_keywords:'';
        return (
            <>
                 <div className="content-wrapper">
                 <Breadcrumb BreadcrumbData={BreadcrumbData} />
               
           
               <div className="container">
             
               <div className="page-desc">
               {
                !isLoading ?
                <>
             
                <div className="section-title text-center">
                    <h2>{pageDetails.title}</h2>
                    <div className="border-bottom-line">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="service-bottom-content text-center">
                    <p><strong> {pageDetails.subtitle}</strong></p> 
                </div>
         
                <div className="innerpage-content">{parse(pageDetails.description)}</div>
                </>
                :<div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>
                }
                </div>
               </div>
               </div>
            </>
        )
    }
}
