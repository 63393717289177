import React from 'react'

const Modal = ({ show, children }) => {
    return (
        <div className="modal-wrapper"
        style={{
            transform:show ?'translateY(0vh)':'translateY(-200vh)',
            opacity:show ? '1' : '0'
        }}
        >
          {children}
        </div>
    )
}

export default Modal
