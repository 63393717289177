import React, { Component } from 'react'
import { GetPages } from '../../helper'
import Breadcrumb from '../common/Breadcrumb'
const BreadcrumbData = [
    {
        id:1,
        page_title:"Home",
        page_path:"/",
        icon:"ews ewse_gaphicsdes",
    },
    {
        id:2,
        page_title:"Privacy Policy",
        page_path:"/about",
        icon:"ews ewse_gaphicsdes",
    }
]
export default class PrivacyPolicy extends Component {
    constructor(){
        super();
        this.state={
          isLoading:true,
          pageDetails:null
        }
      }
      async componentDidMount(){
      
        let resp = await GetPages.getPrivacyPolicy();
        if(resp){
            this.setState({
                pageDetails: resp.privacy_policy,
                isLoading:false
            })
        }else{
            this.setState({
             isLoading:false
         }) 
      }
    }
    render() {
        const {pageDetails, isLoading} = this.state; 
        return (
            <>
                 <div className="content-wrapper">
                 <Breadcrumb BreadcrumbData={BreadcrumbData} />
                 <div className="inner-page-header">
                            <h1>Privacy Policy</h1>
                        </div>
           
               <div className="container">
             
               <div className="page-desc">
               {
                !isLoading ?
                <>
                <div className="section-title text-center">
                    <h2>{pageDetails.title}</h2>
                    <div className="border-bottom-line">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="service-bottom-content text-center">
                    <p><strong> {pageDetails.subtitle}</strong></p>
                    <p className="mb-30">{pageDetails.excerpt}</p>
                   
                </div>
                </>
                :<div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div>
                }
                </div>
               </div>
               </div>
            </>
        )
    }
}
