import React, { Component } from 'react'
import CategoryProductList from './CategoryProductList'
import { GetCategories } from '../../../../helper'
import catImg from '../../../../assets/img/Corporate_Gifts.svg'
import { Link } from 'react-router-dom';
export default class CategoryProduct extends Component {
  constructor(){
    super();
    this.state={
      isLoading:true,
      homeCategories:null
    }
  }
  async componentDidMount(){
  
    let resp = await GetCategories.getHomeCategories();
    if(resp){
        this.setState({
            homeCategories: resp.categories,
            isLoading:false
        })
    }else{
        this.setState({
         isLoading:false
     }) 
  }
}
  render() {
    const {homeCategories, isLoading} = this.state; 
    return (
      <>
    <div className="product-category-section section-padding-one">
      <div className="container">
          <div className="section-title">
            <h2>Product Category</h2>
            <div className="border-bottom-line">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

           <div className="row">
           {
            !isLoading ? homeCategories.map((item, index) => {
                        return <CategoryProductList key={index} item={item} />
            }) 
                           
        :null
      }
     
         <div className="col-2 col-sm-6">
              <Link to="/category/corporate-gifts">
              <div className="category-box text-center">
                <div className="category-image">
                <img src={ catImg } alt="Luminous Printing"/>
                </div>
                <div className="category-title">
                 <h4>Corporate Gifts</h4>
                </div>
              </div>
              </Link>
            </div>
      
     
            </div>
          </div>
      </div>
        </>
    )
  }
}
