import React from 'react'
import { Link } from "react-router-dom";
import parse from 'html-react-parser'
const PostItem = ({item}) =>{
    return (
        <div className="col-4 col-md-4 col-sm-6 col-xs-12 pd-10">
        <Link to={`/blog/${item.slug}`}>
        <div className="post-box-wrapper">
            <div className="post-thumbnail">
                <img className="hover-zoom" src={item.featured_image} alt="" />
            </div>
            <div className="post-small-desc">
            <div className="post-title">
                <h4>{ item.title }</h4>
            </div>
            <div className="post-excerpt">
              { parse(item.exerpt) }
            </div>
            </div>
        </div>
        </Link>
      </div>
    )
}

export default PostItem
