import api from '../../ApiConfig';
import { Apis } from '../../config';

const getAboutDetails = async () => {
    try {
        let result = await api.get(Apis.GetAboutDetails);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}; 



const getHomeFaq = async () => {
    try {
        let result = await api.get(Apis.GetHomeFaqs);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
const getFaqs = async () => {
    try {
        let result = await api.get(Apis.GetFaqs);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getPrintingMethods = async () => {
    try {
        let result = await api.get(Apis.GetPrintingMethods);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getSinglePrintingMethods = async (slug) => {
    try {
        let result = await api.get(Apis.GetSinglePrintingMethods + slug);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllPrintingMethods = async () => {
    try {
        let result = await api.get(Apis.GetAllPrintingMethods);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getLatestPortfolio = async () => {
    try {
        let result = await api.get(Apis.GetLatestPortfolio);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getAllPortfolio = async () => {
    try {
        let result = await api.get(Apis.GetAllPortfolio);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getTermsCondition = async () => {
    try {
        let result = await api.get(Apis.GetTermCondition);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getPrivacyPolicy = async () => {
    try {
        let result = await api.get(Apis.GetPrivacyPolicy);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getHomepageRecentProjects = async () => {
    try {
        let result = await api.get(Apis.GetHomepageRecentProjects);
        return result.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};


const exportedObject = {
    getAboutDetails,
    getHomeFaq,
    getFaqs,
    getPrintingMethods,
    getSinglePrintingMethods,
    getAllPrintingMethods,
    getLatestPortfolio,
    getAllPortfolio,
    getTermsCondition,
    getPrivacyPolicy,
    getHomepageRecentProjects
};
export default exportedObject;