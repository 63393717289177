import React from 'react'
import Breadcrumb from '../common/Breadcrumb'
import Sidebar from '../auth/account/Sidebar'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "User Dashboard",
        page_path: "/category",
        icon: "ews ewse_gaphicsdes",
    }
]
const DashboardLayout = ({ children }) => {
    return (
        <>
            <div className="content-wrapper">
                <Breadcrumb BreadcrumbData={BreadcrumbData} />;
                <div className="container">
                 <div className="row">
                     <div className="col-3">
                       <Sidebar/>
                     </div>
                     <div className="col-9">
                     {children}
                    </div>
                 </div>
                </div>
            </div>
        </>
    )
}

export default DashboardLayout
