import React, { Component } from 'react'
import Breadcrumb from '../common/Breadcrumb';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Login from './Login'
import Register from './Register'
import { GetUserLogin, Alerts } from '../../helper';
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Login/Register",
        page_path: "/login",
        icon: "ews ewse_gaphicsdes",
    }
]

export class AuthWrapper extends Component {
    constructor(){
        super();
        this.state={
            isLoading:false
        }
    }

    onGoogleLogin = async (data) => {
     this.setState({
        isLoading:true
     });
        let resp = await GetUserLogin.getSocialLogin(data);
        if (resp) {
            console.log(resp);
            Alerts.alertMessage("You are logged in successfully.", "Logged in", "success");
            this.persistUserDetails(resp);
        }
    }

    onRegister = async (data) => {
        Alerts.alertMessage("You are register & logged in successfully.", "Logged in", "success");
        this.persistUserDetails(data);
    }
    onLogin = async (data) => {

        let resp = await GetUserLogin.getUserLogin(data);
        if (resp) {
            Alerts.alertMessage("You are logged in successfully.", "Logged in", "success");
            this.persistUserDetails(resp);

        } else {
            Alerts.alertMessage("Please check your email & password.", "Input Error", "danger");
        }
    }
    persistUserDetails = (data) => {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userData', JSON.stringify(data.user));
        localStorage.setItem('userToken', JSON.stringify(data.token));
        this.isLoggedIn();
    };
    isLoggedIn = () => {
        let isAuthenticated = localStorage.getItem('isAuthenticated');
        if (isAuthenticated && isAuthenticated === 'true') {
            let from = '/';
            if (this.props.location.state) {
                from = this.props.location.state.pre_path;
            }
            // return this.props.history.push(from);
            this.setState({
                isLoading:false
             });
            setTimeout(function () {
                window.location.href = from; //will redirect to your blog page (an ex: blog.html)
            }, 2000); //will call the function after 2 secs.

        }

    };
    render() {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        if (isAuthenticated && isAuthenticated === 'true') {
            this.props.history.push('/');
        }
        return (
            <div className="content-wrapper">
                <Breadcrumb BreadcrumbData={BreadcrumbData} />
                <div className="container">
                    <div className="auth-container-outer">
                        
                      
                      
                        {
                        this.state.isLoading ? <div className="auth-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader" style={{maxWidth: '45px'}}/> Please Wait...</div> :
                        <div className="auth-container">
                            <Tabs>
                                <TabList>
                                    <Tab>Login</Tab>
                                    <Tab>Register</Tab>
                                </TabList>

                                <TabPanel>
                                
                                     <Login onLogin={this.onLogin} onGoogleLogin={this.onGoogleLogin} />
                                
                                   
                                </TabPanel>
                                <TabPanel>
                                    <Register onRegister={this.onRegister} />
                                </TabPanel>
                            </Tabs>
                            </div>
                        } 
                       
                    </div>
                </div>
            </div>
        )
    }
}

export default AuthWrapper
