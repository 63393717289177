import React from 'react'

const RecentProjectItems = ({item, onViewImage}) => {
    return (
        <div className="col-3 col-md-4 col-sm-6 col-xs-12 pd-10">
        <button onClick={()=>onViewImage(item.id)}>
        <div className="portfolio_box">
            <div className="portfolio_image">
              <img className="hover-zoom" src={item.thumbnail_image} alt=""/>
            </div>
            <div className="portfolio-title">
             <h4>{item.title}</h4> 
            </div>
        </div>
        </button>
      </div>
    )
}

export default RecentProjectItems
