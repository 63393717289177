
const lanyard_joining_options = [
    {
        text: "Stitched Joint",
        image: "lj1.jpg",
        value: "Stitched Joint"
    },
    {
        text: "Crimped Joint",
        image: "lj2.jpg",
        value: "Crimped Joint"
    },
    {
        text: "Riveted Joint",
        image: "lj3.jpg",
        value: "Riveted Joint"
    },
]

export default lanyard_joining_options;