import React, { Component } from 'react'
import { GetPosts } from '../../../helper'
import Breadcrumb from '../../common/Breadcrumb'
import PostItem from './PostItem'
const BreadcrumbData = [
    {
        id: 1,
        page_title: "Home",
        page_path: "/",
        icon: "ews ewse_gaphicsdes",
    },
    {
        id: 2,
        page_title: "Blogs",
        page_path: "/blogs",
        icon: "ews ewse_gaphicsdes",
    }
]
export default class Posts extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            posts: null
        }
    }
    async componentDidMount() {

        let resp = await GetPosts.getAllPost();
        if (resp) {
            this.setState({
                posts: resp.posts,
                isLoading: false
            })

        } else {
            this.setState({
                isLoading: false
            })
        }
    }
    render() {
        const { posts, isLoading } = this.state;

        document.title = 'blogs';
        document.getElementsByTagName("META")[3].content = 'blogs';
        document.getElementsByTagName("META")[4].content = 'blogs';
        return (
            <>
                <div className="content-wrapper">
                    <Breadcrumb BreadcrumbData={BreadcrumbData} />
                    <div className="container">
                        <div className="page-desc">
                        {
                            isLoading ?<div className="listing-loader"><img src={process.env.PUBLIC_URL + "../assets/img/data-loader.gif"} alt="product-loader"/></div> :<div className="row">
                            {
                                posts.map((item, index) => {
                                    return <PostItem key={index} item={item} />
                                }) 
                            }
                           </div>
                        }
                           
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
