import React, { Component } from 'react'
import { GetUserLogin } from '../../helper';
import Recaptcha from 'react-google-invisible-recaptcha';
export class Register extends Component {
    constructor() {
        super();
        this.state = {
            fname: '',
            lname: '',
            email: '',
            mobile_number: '',
            password: '',
            c_password: '',
            errors: [],
            isLoading: false

        }
        // this.onResolved = this.onResolved.bind( this );
    }
    onResolved = async () => {
            const { fname, lname, email, mobile_number, password, c_password } = this.state;
            const data = {
                'fname': fname,
                'lname': lname,
                'email': email,
                'mobile_number': mobile_number,
                'password': password,
                'c_password': c_password
            }

            let resp = await GetUserLogin.getUserRegister(data);
            if (resp) {
                if (resp.status === false) {
                    this.recaptcha.reset();
                    this.setState({
                        errors: resp.errors,
                        isLoading: false
                    });
                }
                if (resp.status === true) {
                    this.props.onRegister(resp);
                }
            }
        
    }
    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };
    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })

        this.recaptcha.execute();
    }
    render() {
        const { fname, lname, email, mobile_number, password, c_password, errors, isLoading } = this.state;
        return (
            <div className="form-wrapper">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group row">
                    <div className="col-6 col-sm-12">
                        <input type="text" className="form-control" placeholder="First Name" name="fname" value={fname} onChange={this.handleChange} />
                        <span className="error-help">{errors['fname']}</span>
                    </div>
                    <div className="col-6 col-sm-12">
                    <input type="text" className="form-control" placeholder="Last Name" name="lname" value={lname} onChange={this.handleChange} />
                        <span className="error-help">{errors['lname']}</span>
                    </div>
                    </div>
                   
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Email" name="email" value={email} onChange={this.handleChange} />
                        <span className="error-help">{errors['email']}</span>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Mobile Number" name="mobile_number" value={mobile_number} onChange={this.handleChange} />
                        <span className="error-help">{errors['mobile_number']}</span>
                    </div>
                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Password" name="password" value={password} onChange={this.handleChange} />
                        <span className="error-help">{errors['password']}</span>
                    </div>
                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Confirm Password" name="c_password" value={c_password} onChange={this.handleChange} />
                        <span className="error-help">{errors['c_password']}</span>
                    </div>
                    <Recaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey="6LcgigUbAAAAAGiYF2mQXerrHRqXrV38fMcNNRWi"
                        onResolved={this.onResolved}
                    />
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block">{isLoading ? 'Please wait..' : 'Register'}</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default Register
