import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuItems = ({subnav}) => {
   
    return (
         <div className="col-3">
         { !subnav.category ? null :
         <>
          <h3><NavLink to={'/category/' +  subnav.category.slug}> { subnav.category.cat_icon } {subnav.category.title}</NavLink></h3>
          {
              
            subnav.category.children.map((child_subnav, i) => {
                return <NavLink to={'/category/'+subnav.category.slug+'/' + child_subnav.slug}>{child_subnav.title}</NavLink>
            })
              }
          
      
           <hr className="divider"/>
           </>
         }
          </div>


    
    )
}

export default MenuItems
