import React, { Component } from 'react'
import axios from 'axios';
export default class NewProductDetails extends Component {
    componentDidMount(){
        const { match: { params } } = this.props;
        console.log(params.category_slug);
        console.log(params.slug);
        axios.get(`http://luminousapi.articloo.com/api/product/${params.category_slug}/${params.slug}`)
        .then(res => {
            const resData = res.data.product;
            console.log(resData)
           
        }).catch((error) => {
            console.log(error)
          
        });
    }
    render() {
        return (
            <div>
                <h1>Product Details</h1>
            </div>
        )
    }
}
