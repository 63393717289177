// import {combineReducers} from 'redux'
// import cardItems from './reducer'

// export default combineReducers({
//     cardItems,
// })
import {combineReducers } from "redux";
import { cartReducer } from "./cartReducer";

export default combineReducers({
    cart: cartReducer
}); 

